import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from "./Auth";
import {
    Card,
    Button,
    Image,
    Spinner,
    Modal,
    Alert,
    Badge
} from 'react-bootstrap';
import {
    Link
} from "react-router-dom";
import { faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AcademyNavbar from './AcademyNavbar';
import AcademyFooter from './AcademyFooter';
import firebase from 'firebase';
import { library } from '@fortawesome/fontawesome-svg-core';
import DashboardNavbar from '../Dashboard/DashboardNavbar'
import Axios from 'axios';
import moment from 'moment';
import _ from 'lodash';


library.add(faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight);

const SendEmails = (props) => {
    const {currentUser} = useContext(AuthContext);
    const [programs, setPrograms] = useState([]);
    const [programList, setProgramList] = useState([]);
    const [orders, setOrders] = useState([]);
    const [orderParticipants, setOrderParticipants] = useState([]);
    useEffect(() => {

        Axios.get(`https://dev.igesia.com/orders/success`)
        .then((res) => {
            const tempOrder = [];
            res.data.data.map((order) => {
                tempOrder.push(order);
            })
            setOrders(tempOrder);
        })


    },[])
    // Getting programs
    useEffect(() => {
        let programsRef = firebase.firestore().collection('executivePrograms');
        programsRef.get()
            .then(snapshot => {
                let tempProgramsList = [];
                snapshot.forEach(doc => {
                    tempProgramsList.push({ programId: doc.id, ...doc.data() });
                });
                setPrograms(tempProgramsList);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
    }, [])

    // getting programlist
    useEffect(() => {
        let programsRef = firebase.firestore().collection('programList');
        programsRef.get()
            .then(snapshot => {
                let tempProgramsList = [];
                snapshot.forEach(doc => {
                    tempProgramsList.push({ programSlug: doc.id, ...doc.data() });
                });
                setProgramList(tempProgramsList);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });

    },[])
    // getting orderParticipants against orderid in orders (state)
    useEffect(() => {
        if(orders.length !== 0){
            let orderParticipantsRef = firebase.firestore().collection('orderParticipants');
            orderParticipantsRef.get()
                .then(snapshot => {
                    let tempProgramsList = [];
                    snapshot.forEach(doc => {
                        orders.map((order) => {
                            if(order.order.order_id === doc.id){
                                let subordersGrouped = doc.data().suborders;
                                subordersGrouped = _.groupBy(subordersGrouped, 'product_id');
                                let data = doc.data();
                                data.suborders = subordersGrouped;
                                tempProgramsList.push(data);
                            }
                        })
                    });
                    const orderParticipantsFilled = (_.groupBy(tempProgramsList,'order_id'));
                    setOrderParticipants(orderParticipantsFilled);
                })
                .catch(err => {
                    console.log('Error getting documents', err);
                });
        }
    },[orders])

    useEffect(() => {
        let programsRef = firebase.firestore().collection('programList');
        programsRef.get()
            .then(snapshot => {
                let tempProgramsList = [];
                snapshot.forEach(doc => {
                    tempProgramsList.push({ programSlug: doc.id, ...doc.data() });
                });
                setProgramList(tempProgramsList);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });

    },[])
    const getProgramNameHandler = (id) => {
        let programName = '';
        programs.forEach((program) => {
            if(program.programId === id){
                programName = program.programName;
            }
        })
        return programName;
    }
    const getProgramThumbnailHandler = (id) => {
        let programThumbnail = '';
        programList.forEach((program) => {
            if(program.programId === id){
                programThumbnail = program.programThumbnail;
            }
        })
        return programThumbnail;
    }

    const sendRegistrationEmailHandler = (orderid, firstName, email, programId) => {
        Axios.post(`https://dev.igesia.com/orders/${orderid}/send-notification`, {
            participants: [{
                email: email,
                first_name: firstName
            }],
            product_id: programId
        }).then((response) => {
            if(response.data.status === 200){
                // console.log(response)
                alert('Email sent successfully!')
            }
        })
    }

    const sendReminderEmailHandler = (orderid) => {
        Axios.post(`https://dev.igesia.com/orders/${orderid}/send-reminder`).then((response) => {
            if(response.data.status === 200){
                // console.log(response)
                alert('Email sent successfully!')
            }
        })
    }




    return (
        <>
            <DashboardNavbar />
            <div className="container" style={{maxWidth: 920}}>
                <h2 className="text-white">Your orders</h2>
                    {
                        orders.map((order, index) => {
                            const o_id = order.order.order_id;
                            const firstName = order.order.lead.first_name;
                            const email = order.order.lead.email;
                            return(
                                <div class="card bg-dark text-white my-3">
                                    <div class="card-header d-flex justify-content-between small flex-column flex-md-row">
                                        <div className="d-flex d-md-block justify-content-between">
                                            <div>
                                                Order Placed
                                            </div>
                                            <div>
                                                {moment(order.order.createdAt).format('LLL')}
                                            </div>
                                        </div>
                                        <div className="d-flex d-md-block justify-content-between">
                                            <div>
                                                Total
                                            </div>
                                            <div>
                                                ₹ {order.transaction_amount/100}
                                            </div>
                                        </div>
                                        <div className="d-flex d-md-block justify-content-between">
                                            <div>
                                                Registered By
                                            </div>
                                            <div>
                                                {order.order.lead.first_name} {order.order.lead.last_name}
                                            </div>
                                        </div>
                                        <div className="d-flex d-md-block justify-content-between">
                                            <div>
                                                Order ID
                                            </div>
                                            <div>
                                                {order.order.order_id}
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        order.order.suborders.map((suborder) => {
                                            return(
                                                <div class="card-body d-flex align-items-center small">
                                                    <img src={getProgramThumbnailHandler(suborder.product_id)} className="img-fluid d-none d-sm-block" style={{width: '100px'}}></img>
                                                    <div className="d-flex w-100 justify-content-around align-items-center">
                                                        <div className="d-flex flex-column justify-content-around w-50">
                                                        <span class="card-text">{getProgramNameHandler(suborder.product_id)}</span>
                                                        <span class="card-text mt-3 text-secondary">Quantity {suborder.quantity}</span>
                                                        </div>
                                                        <div className="d-flex flex-column">
                                                            <button type="button" class="btn btn-outline-light btn-sm mt-3" onClick={() => sendRegistrationEmailHandler(o_id, firstName, email, suborder.product_id)}>Send Registration Email</button>
                                                            {
                                                                orderParticipants[o_id] ? orderParticipants[o_id][0].suborders[suborder.product_id] ? 
                                                                <small className="mt-2 text-center">Details filled</small>
                                                                : 
                                                                <button type="button" class="btn btn-outline-light btn-sm mt-3" onClick={() => sendReminderEmailHandler(o_id, suborder.quantity, suborder.product_id)}>Send Reminder Email</button>
                                                                : 
                                                                <button type="button" class="btn btn-outline-light btn-sm mt-3" onClick={() => sendReminderEmailHandler(o_id)}>Send Reminder Email</button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            );
                        })
                    }
            </div>
            <AcademyFooter />
        </>
    );
}


export default SendEmails;

import React, { useEffect, useState } from "react";
import {
  Card,
  Popover,
  OverlayTrigger,
  Button,
  Nav,
  NavDropdown,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAward,
  faVideo,
  faUsers,
  faChalkboardTeacher,
  faLightbulb,
  faAngleDoubleDown,
  faArrowRight,
  faAngleDoubleUp
} from "@fortawesome/free-solid-svg-icons";
import { Link, useHistory } from "react-router-dom";
import AcademyNavbar from "./AcademyNavbar";
import AcademyFooter from "./AcademyFooter";
import firebase from "firebase";
import background from "../../images/polygon.png";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

const categoryoptions = (
  <Popover id="popover-basic">
    <Popover.Title as="h3">Cateogry</Popover.Title>
    <Popover.Content>
      And here's some <strong>amazing</strong> content. It's very engaging.
      right?
    </Popover.Content>
  </Popover>
);

const Category = () => (
  <OverlayTrigger trigger="click" placement="bottom" overlay={categoryoptions}>
    <Button
      className="mb-5 mr-3 rounded small"
      variant="outline-light"
      style={{ borderColor: "#555555" }}
    >
      Category
    </Button>
  </OverlayTrigger>
);

const Dates = () => (
  <OverlayTrigger trigger="click" placement="bottom" overlay={categoryoptions}>
    <Button
      className="mb-5 mr-3 rounded small"
      variant="outline-light"
      style={{ borderColor: "#555555" }}
    >
      Dates
    </Button>
  </OverlayTrigger>
);

function Academy(props) {
  const handleChange = function (event) {
    console.log(event.target.value);
  };

  const history = useHistory();

  function handleClick() {
    history.push("/program");
  }

  const [programList, setProgramList] = useState([]);
  const [loadingProgramList, setLoadingProgramList] = useState(true);

  const [shortCoursesList, setShortCoursesList] = useState([]);
  const [loadingShortCourses, setLoadingShortCourses] = useState(true);

  useEffect(() => {
    let programListRef = firebase
      .firestore()
      .collection("/Variants/pt-BR/programList/");
    let allPrograms = programListRef
      .orderBy("position", "asc")
      .get()
      .then((snapshot) => {
        let tempProgramList = [];

        snapshot.forEach((doc) => {
          tempProgramList.push({ programSlug: doc.id, ...doc.data() });
        });
        setProgramList(tempProgramList);
        setLoadingProgramList(false);
        console.log(tempProgramList);
      })
      .catch((err) => {
        console.log("Error getting documents", err);
      });

    let shortCourseListRef = firebase
      .firestore()
      .collection("/Variants/pt-BR/shortCoursesList/");
    shortCourseListRef
      .orderBy("programSuperset", "asc")
      .get()
      .then((snapshot) => {
        let tempProgramLists = [];

        snapshot.docs.forEach((doc) => {
          tempProgramLists.push({ programSlug: doc.id, ...doc.data() });
        });
        setShortCoursesList(tempProgramLists);
        setLoadingShortCourses(false);
      })
      .catch((err) => {
        console.log("Error getting documents", err);
      });
  }, []);
  // referral

  useEffect(() => {
    // check whether the url has ref or not
    console.log(props);
    if (!props.match.params.ref || !props.match.params[0]) {
      //if no ref in url, skip
    } else if (props.match.params.ref) {
      // url has ref
      if (window.localStorage.getItem("ref")) {
        // there is already a ref in local storage
        // console.log('LS has ref already!');
        // incrementing count of new ref but not replacing LS
        const indexRef = window.localStorage.getItem("ref");
        let ref = [];
        if (indexRef[0] === "[") {
          ref = JSON.parse(window.localStorage.getItem("ref"));
        } else {
          ref = [window.localStorage.getItem("ref")];
        }
        ref.push(props.match.params.ref);
        firebase
          .firestore()
          .collection("referrals")
          .doc(props.match.params.ref)
          .update({
            referralLinkVisited: firebase.firestore.FieldValue.increment(1),
          });
        window.localStorage.setItem("ref", JSON.stringify(ref));
      } else if (!window.localStorage.getItem("ref")) {
        // no ref in local storage
        const ref = [];
        ref.push(props.match.params.ref);
        window.localStorage.setItem("ref", JSON.stringify(ref));
        firebase
          .firestore()
          .collection("referrals")
          .doc(props.match.params.ref)
          .update({
            referralLinkVisited: firebase.firestore.FieldValue.increment(1),
          });
      }
    }
  }, []);

  const getShortCourseCategoryHandler = (superset) => {
    const superSets = [
      "Liderando a Transformação Digital e Inovação",
      "Inteligência artificial: Princípios e Técnicas",
      "Gestão da Inovação: Estratégia e Execução",
    ];
    return superSets[superset];
  };

  const [customizableData, setCustomizableData] = useState();
  const [heroPhotoURL, setHeroPhotoURL] = useState("");
  const [heroHeading, setHeroHeading] = useState("");
  const [heroSubTitle, setHeroSubTitle] = useState("");
  const [heroPhotoCaptions, setHeroPhotoCaptions] = useState("");
  const [heroPointers, setHeroPointers] = useState([]);

  useEffect(() => {
    firebase
      .firestore()
      .collection("/Variants/pt-BR/customizablePages/")
      .doc("home")
      .get()
      .then((doc) => {
        if (doc.exists) {
          setCustomizableData(doc.data());
          setHeroHeading(doc.data().heroHeading);
          setHeroSubTitle(doc.data().heroSubTitle);
          setHeroPhotoURL(doc.data().heroPhotoURL);
          setHeroPointers(doc.data().heroPointers);
        }
      });
  }, []);

  return (
    <>
      <AcademyNavbar />
      <div className="container">
        <div className="d-flex justify-content-between" style={{ borderTop: '1px solid rgb(154 154 154)', borderBottom: '1px solid rgb(154 154 154)' }}>
          <Link className="nav-link text-white" to="/about">Sobre a Igesia</Link>
          <Link className="nav-link text-white" to="/contact">Contato</Link>
        </div>
      </div>
      {customizableData ? (
        <div className="container-fluid pt-5">
          <div className="container">
            <div className="row mb-5">
              {/* style={{ borderBottom: "1px solid rgba(255,255,255,0.2)" }} */}
              <div className="col-md-6 text-white py-4 ">
                <h1 className="font-weight-light mb-3">
                  {ReactHtmlParser(heroHeading)}
                </h1>
                <p className="lead">{ReactHtmlParser(heroSubTitle)}</p>
              </div>
              <div className="col-md-6 text-white py-4">
                <div
                  className="w-100 rounded mb-2"
                  style={{
                    backgroundImage: `url(${heroPhotoURL})`,
                    backgroundSize: "cover",
                    height: "300px",
                  }}
                ></div>
                {/* <p className="small">Discurso inaugural do Prêmio Nobel A. Michael Spence (todos os programas) </p> */}
              </div>
            </div>
            <div className="row mb-5 text-white text-center">
              {customizableData.heroPointers.map((point, index) => {
                return (
                  <div className="col-md-3 mb-3 flex-grow-1">
                    <div className="rounded border p-4 ckeditorNoPadding">
                      <h5>{ReactHtmlParser(point.heading)}</h5>
                      <p className="mb-0">{ReactHtmlParser(point.subTitle)}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : null}
      <div className="container-fluid p-0">
        <>
        {loadingProgramList ? (
            <div
              className="container-fluid w-100 d-flex justify-content-center align-items-center"
              style={{ height: "20vh" }}
            >
              <Spinner animation="border" role="status" variant="light">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          ) : (
              <>
                <div className="container-fluid bg-black pt-5"  style={{ backgroundColor: 'rgb(46 51 68)' }}>
                  <div className="container">
                    <div className="d-flex justify-content-between">
                      <h2 className="text-white py-2">Programas Executivos</h2>
                      <a style={{ textDecoration: 'none' }} href="#shortcourses">
                        <h5 className="text-white py-2">Veja os minicursos
                      <FontAwesomeIcon
                            icon={faAngleDoubleDown}
                            size="sm"
                            color="white"
                            className="ml-2"
                          />
                        </h5>
                      </a>
                    </div>
                    <div className="row" id="executiveprograms">
                      {programList.map((item, key) => {
                        if (+item.position > 0) {
                          if(!item.externalURL) {
                            return (
                              <div className="col-md-4 pt-5" key={key}>
                                <Link
                                  className="link"
                                  to={`/program/${item.programSlug}`}
                                >
                                  <Card
                                    className="mb-3 border-0"
                                    bg="transparent"
                                    text="white"
                                  >
                                    <Card.Img
                                      variant="top"
                                      src={item.programThumbnail}
                                    />
                                    <Card.Body className="px-0">
                                      {/* <span className="small mb-4 text-warning">STARTS OCTOBER 2020</span> */}
                                      <Card.Title>{item.programTitle}</Card.Title>
                                      <Card.Text className="small">
                                        {item.programSubTitle}
                                      </Card.Text>
                                      <div className="row">
                                        <div className="col-12 ">
                                          {item.associatedUniversities && (
                                            <div>
                                              <p className="small font-weight-bold text-muted pt-3 border-top">
                                                Aprenda e se relacione por meio de aulas
                                                online ao vivo com professores de renome
                                                mundial da:
                                        </p>
                                              <div className="row">
                                                {item.associatedUniversities.map(
                                                  (university) => (
                                                    <p className="col-md-6 mb-1 small ">
                                                      {university}
                                                    </p>
                                                  )
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                        {
                                          !item.fiaLogo ?
                                            <div className="col-12">
                                              <p className="small mt-3">
                                                Atestado com registro de participação da
                                      Extensão da{" "}
                                                <strong>
                                                  Universidade da Califórnia, Los Angeles
                                                  (UCLA)
                                      </strong>
                                              </p>
                                              <img
                                                alt="UCLA Logo"
                                                className="mb-3"
                                                src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fucla_reverse.png?alt=media&token=adbdeab7-41e8-4d9a-a1a7-9f2d8fb3f5ac"
                                                width="30%"
                                              />
                                            </div>
                                            : null
                                        }
                                        {
                                          item.fiaLogo ?
                                            <>
                                              <div className="col-12">
                                                <p className="small mt-3">
                                                  Certificado de participação da {" "}
                                                  <strong>
                                                    UCLA Extension e FIA
                                                  </strong>
                                                </p>
                                                <div className="d-flex justify-content-between align-items-center">
                                                  <img
                                                    alt="UCLA Logo"
                                                    className="mb-3"
                                                    src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fucla_reverse.png?alt=media&token=adbdeab7-41e8-4d9a-a1a7-9f2d8fb3f5ac"
                                                    width="30%"
                                                  />
                                                  <img
                                                    alt="FIA Logo"
                                                    className="mb-3 bg-white"
                                                    src={item.fiaLogo}
                                                    width="30%"
                                                  />
                                                </div>
  
                                              </div>
                                            </>
                                            : null
                                        }
                                      </div>
                                      <span className="text-muted">
                                        Saiba mais{" "}
                                        <FontAwesomeIcon
                                          icon={faArrowRight}
                                          size="sm"
                                          color="grey"
                                        />
                                      </span>
                                    </Card.Body>
                                  </Card>
                                </Link>
                              </div>
                            );
                          } else if(item.externalURL) {
                            return (
                              <div className="col-md-4 pt-5" key={key}>
                                <div
                                  className="link"
                                  style={{cursor: 'pointer'}}
                                  onClick={() => window.open(item.externalURL)}
                                >
                                  <Card
                                    className="mb-3 border-0"
                                    bg="transparent"
                                    text="white"
                                  >
                                    <Card.Img
                                      variant="top"
                                      src={item.programThumbnail}
                                    />
                                    <Card.Body className="px-0">
                                      {/* <span className="small mb-4 text-warning">STARTS OCTOBER 2020</span> */}
                                      <Card.Title>{item.programTitle}</Card.Title>
                                      <Card.Text className="small">
                                        {item.programSubTitle}
                                      </Card.Text>
                                      <div className="row">
                                        <div className="col-12 ">
                                          {item.associatedUniversities && (
                                            <div>
                                              <p className="small font-weight-bold text-muted pt-3 border-top">
                                                Aprenda e se relacione por meio de aulas
                                                online ao vivo com professores de renome
                                                mundial da:
                                        </p>
                                              <div className="row">
                                                {item.associatedUniversities.map(
                                                  (university) => (
                                                    <p className="col-md-6 mb-1 small ">
                                                      {university}
                                                    </p>
                                                  )
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                        {
                                          !item.fiaLogo ?
                                            <div className="col-12">
                                              <p className="small mt-3">
                                                Atestado com registro de participação da
                                      Extensão da{" "}
                                                <strong>
                                                  Universidade da Califórnia, Los Angeles
                                                  (UCLA)
                                      </strong>
                                              </p>
                                              <img
                                                alt="UCLA Logo"
                                                className="mb-3"
                                                src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fucla_reverse.png?alt=media&token=adbdeab7-41e8-4d9a-a1a7-9f2d8fb3f5ac"
                                                width="30%"
                                              />
                                            </div>
                                            : null
                                        }
                                        {
                                          item.fiaLogo ?
                                            <>
                                              <div className="col-12">
                                                <p className="small mt-3">
                                                  Certificado de participação da {" "}
                                                  <strong>
                                                    UCLA Extension e FIA
                                                  </strong>
                                                </p>
                                                <div className="d-flex justify-content-between align-items-center">
                                                  <img
                                                    alt="UCLA Logo"
                                                    className="mb-3"
                                                    src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fucla_reverse.png?alt=media&token=adbdeab7-41e8-4d9a-a1a7-9f2d8fb3f5ac"
                                                    width="30%"
                                                  />
                                                  <img
                                                    alt="FIA Logo"
                                                    className="mb-3 bg-white"
                                                    src={item.fiaLogo}
                                                    width="30%"
                                                  />
                                                </div>
  
                                              </div>
                                            </>
                                            : null
                                        }
                                      </div>
                                      <span className="text-muted">
                                        Saiba mais{" "}
                                        <FontAwesomeIcon
                                          icon={faArrowRight}
                                          size="sm"
                                          color="grey"
                                        />
                                      </span>
                                    </Card.Body>
                                  </Card>
                                </div>
                              </div>
                            );
                          }

                        }
                      })}
                    </div>
                  </div>
                </div>
              </>
            )}
          {
            loadingShortCourses ? (
              <div
                className="container-fluid w-100 d-flex justify-content-center align-items-center"
                style={{ height: "20vh" }}
              >
                <Spinner animation="border" role="status" variant="light">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>)
              :

              <div className="container-fluid pt-5">
                <div className="container">
                  <div className="d-flex justify-content-between">
                    <h2 className="text-white py-2">Cursos Curtos</h2>
                    <a style={{ textDecoration: 'none' }} href="#executiveprograms">
                      <h5 className="text-white py-2">Ver programas executivos
                      <FontAwesomeIcon
                          icon={faAngleDoubleUp}
                          size="sm"
                          color="white"
                          className="ml-2"
                        />
                      </h5>
                    </a>
                  </div>
                  <div id="shortcourses" className="row">
                    {!loadingShortCourses ? (
                      <div className="col-12">
                        <h3 className="text-light py-3">
                          {getShortCourseCategoryHandler(0)}
                        </h3>
                      </div>
                    ) : null}

                    {!loadingShortCourses &&
                      shortCoursesList.length &&
                      shortCoursesList.map((shortCourse, index) => {
                        if (shortCourse.programSuperset === 0) {
                          return (
                            <>
                              <div className="col-md-4">
                                <Link
                                  className="link"
                                  to={`/shortcourse/${shortCourse.programSlug}`}
                                >
                                  <Card
                                    className="mb-3 border-0"
                                    bg="transparent"
                                    text="white"
                                  >
                                    <Card.Img
                                      variant="top"
                                      src={
                                        shortCourse.images
                                          ? shortCourse.images.compressed
                                          : "https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/public%2Fvariants%2Fpt-BR%2Fprogram-thumbnails%2Fm6zCOHqc8?alt=media&token=6a2674d6-a9f6-4f97-a3fe-ff5b890ba7f6"
                                      }
                                    />
                                    <Card.Body className="px-0">
                                      {/* <span className="small mb-4 text-warning">STARTS OCTOBER 2020</span> */}
                                      <Card.Title>{shortCourse.programName}</Card.Title>
                                      {/* <Card.Text className="small">
                                        {
                                          shortCourse.timeAndDates
                                        }
                                      </Card.Text> */}
                                      <span className="text-muted">
                                        Saiba mais{" "}
                                        <FontAwesomeIcon
                                          icon={faArrowRight}
                                          size="sm"
                                          color="grey"
                                        />
                                      </span>
                                    </Card.Body>
                                  </Card>
                                </Link>
                              </div>
                            </>
                          );
                        }
                      })}
                  </div>
                  <div className="row">
                    {!loadingShortCourses ? (
                      <div className="col-12">
                        <h3 className="text-light py-3">
                          {getShortCourseCategoryHandler(1)}
                        </h3>
                      </div>
                    ) : null}

                    {!loadingShortCourses &&
                      shortCoursesList.length &&
                      shortCoursesList.map((shortCourse, index) => {
                        if (shortCourse.programSuperset === 1) {
                          return (
                            <>
                              <div className="col-md-4">
                                <Link
                                  className="link"
                                  to={`/shortcourse/${shortCourse.programSlug}`}
                                >
                                  <Card
                                    className="mb-3 border-0"
                                    bg="transparent"
                                    text="white"
                                  >
                                    <Card.Img
                                      variant="top"
                                      src={
                                        shortCourse.images
                                          ? shortCourse.images.compressed
                                          : "https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/public%2Fvariants%2Fpt-BR%2Fprogram-thumbnails%2Fm6zCOHqc8?alt=media&token=6a2674d6-a9f6-4f97-a3fe-ff5b890ba7f6"
                                      }
                                    />
                                    <Card.Body className="px-0">
                                      {/* <span className="small mb-4 text-warning">STARTS OCTOBER 2020</span> */}
                                      <Card.Title>{shortCourse.programName}</Card.Title>
                                      {/* <Card.Text className="small">
                                        {
                                          shortCourse.timeAndDates
                                        }
                                      </Card.Text> */}
                                      <span className="text-muted">
                                        Saiba mais{" "}
                                        <FontAwesomeIcon
                                          icon={faArrowRight}
                                          size="sm"
                                          color="grey"
                                        />
                                      </span>
                                    </Card.Body>
                                  </Card>
                                </Link>
                              </div>
                            </>
                          );
                        }
                      })}
                  </div>
                  <div className="row">
                    {!loadingShortCourses ? (
                      <div className="col-12">
                        <h3 className="text-light py-3">
                          {getShortCourseCategoryHandler(2)}
                        </h3>
                      </div>
                    ) : null}
                    {!loadingShortCourses &&
                      shortCoursesList.length &&
                      shortCoursesList.map((shortCourse, index) => {
                        if (shortCourse.programSuperset === 2) {
                          return (
                            <>
                              <div className="col-md-4">
                                <Link
                                  className="link"
                                  to={`/shortcourse/${shortCourse.programSlug}`}
                                >
                                  <Card
                                    className="mb-3 border-0"
                                    bg="transparent"
                                    text="white"
                                  >
                                    <Card.Img
                                      variant="top"
                                      src={
                                        shortCourse.images
                                          ? shortCourse.images.compressed
                                          : "https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/public%2Fvariants%2Fpt-BR%2Fprogram-thumbnails%2Fm6zCOHqc8?alt=media&token=6a2674d6-a9f6-4f97-a3fe-ff5b890ba7f6"
                                      }
                                    />
                                    <Card.Body className="px-0">
                                      {/* <span className="small mb-4 text-warning">STARTS OCTOBER 2020</span> */}
                                      <Card.Title>{shortCourse.programName}</Card.Title>
                                      {/* <Card.Text className="small">
                                        {shortCourse.timeAndDates}
                                      </Card.Text> */}
                                      <span className="text-muted">
                                        Saiba mais{" "}
                                        <FontAwesomeIcon
                                          icon={faArrowRight}
                                          size="sm"
                                          color="grey"
                                        />
                                      </span>
                                    </Card.Body>
                                  </Card>
                                </Link>
                              </div>
                            </>
                          );
                        }
                      })}
                  </div>
                </div>
              </div>
          }
        </>
      </div>
      {/* <div className="container-fluid bg-light py-5" style={{
                backgroundSize: 'cover', backgroundPosition: 'bottom right',
                backgroundImage: 'url(http://themesmummy.com/mitech/static/130fe9ab91ac2fc94b9b15afd0b37809/8c24d/title-bar-01-bg.jpg)'
            }}>
                <div className="container">
                    <div className="row" style={{ borderBottom: "1px solid rgba(255,255,255,0.2)" }}>
                        <div className="col-md-6 text-dark pt-0 pt-md-5 ">
                            <p className="pt-5">RESOURCES</p>
                            <h2 className="font-weight-light">Get a copy of our program calendar</h2>
                            <button className="btn btn-primary btn-md mt-3 px-4 font-weight-bold">Download now</button>
                        </div>
                        <div className="col-md-6" style={{ overflow: "hidden" }}>
                            <img src="http://themesmummy.com/mitech/static/80be319d82ff066e03c711a0e8ba7ad5/7ce22/book-cover.png" />
                        </div>
                    </div>
                </div>
            </div> */}
      <AcademyFooter />
    </>
  );
}

export default Academy;

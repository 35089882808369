import React, { useEffect, useState } from 'react';
import { Card, Popover, OverlayTrigger, Button, Nav, NavDropdown, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import {
    Link, useHistory
} from "react-router-dom";
import AcademyNavbar from '../Academy/AcademyNavbar'
import AcademyFooter from '../Academy/AcademyFooter'
import firebase from 'firebase';
import background from '../../images/polygon.png'
import CKEditor from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';


const categoryoptions = (
    <Popover id="popover-basic">
        <Popover.Title as="h3">Cateogry</Popover.Title>
        <Popover.Content>
            And here's some <strong>amazing</strong> content. It's very engaging.
        right?
      </Popover.Content>
    </Popover>
);

const Category = () => (
    <OverlayTrigger trigger="click" placement="bottom" overlay={categoryoptions}>
        <Button className="mb-5 mr-3 rounded small" variant="outline-light" style={{ borderColor: "#555555" }}>Category</Button>
    </OverlayTrigger>
);

const Dates = () => (
    <OverlayTrigger trigger="click" placement="bottom" overlay={categoryoptions}>
        <Button className="mb-5 mr-3 rounded small" variant="outline-light" style={{ borderColor: "#555555" }}>Dates</Button>
    </OverlayTrigger>
);

function CustomizeAbout() {

    const handleChange = function (event) {
        console.log(event.target.value)
    }

    const history = useHistory();

    function handleClick() {
        history.push("/program");
    }

    const [programList, setProgramList] = useState([]);
    const [loadingProgramList, setLoadingProgramList] = useState(true);

    useEffect(() => {

        let programListRef = firebase.firestore().collection('programList');
        let allPrograms = programListRef.orderBy("position", "asc").get()
            .then(snapshot => {
                let tempProgramList = [];

                snapshot.forEach(doc => {
                    tempProgramList.push({ programSlug: doc.id, ...doc.data() });
                });
                setProgramList(tempProgramList);
                setLoadingProgramList(false);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });

    }, [])

    const [customizableData, setCustomizableData] = useState();
    const [heroHeading, setHeroHeading] = useState('');
    const [heroDesc, setHeroDesc] = useState('');
    const [mainSectionHeading, setMainSectionHeading] = useState('');
    const [mainSectionDesc, setMainSectionDesc] = useState('');
    const [sections, setSections] = useState([]);

    useEffect(() => {
        firebase.firestore().collection('/Variants/pt-BR/customizablePages/').doc('about').get()
            .then((doc) => {
                if(doc.exists){
                    setCustomizableData(doc.data());
                    setHeroHeading(doc.data().heroHeading);
                    setHeroDesc(doc.data().heroDesc);
                    setMainSectionHeading(doc.data().mainSectionHeading);
                    setMainSectionDesc(doc.data().mainSectionDesc);
                    setSections(doc.data().sections);
                }
            })
    },[]);

    const sectionHandler = (type, data, index) => {
        const oldSections = sections;
        const oldData = sections[index];
        if(type === "heading"){
            oldData.sectionHeading = data;
        }else{
            oldData.sectionDesc = data;
        }
        oldSections.splice(index, 1, oldData);
        setSections(oldSections);
    }

    const publishCustomizedPageHandler = () => {
        firebase.firestore().collection('/Variants/pt-BR/customizablePages/').doc('about').update({
            heroHeading,
            heroDesc,
            mainSectionHeading,
            mainSectionDesc,
            sections
        }).then(() => {
            alert('Page published!')
            history.push('/dashboard');
        })
    }

    return (
        <>
            <AcademyNavbar />
            {
                customizableData ?
                <>
                <div className="container-fluid pt-5" style={{ backgroundImage: `url(${background})` }}>
                <div className="container">
                    <div className="row py-4" style={{ borderBottom: "1px solid rgba(255,255,255,0.2)" }}>
                        <div className="col-md-4 text-white">
                            <h1 className="font-weight-light mb-3 ckeditorNoPadding ckeditorNoMargin">
                            <CKEditor
                                editor={ InlineEditor }
                                data={customizableData.heroHeading}
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    console.log( { data } );
                                    setHeroHeading(data);
                                } }
                            />
                            </h1>
                            
                        </div>
                        <div className="col-md-6 text-light pt-3" style={{ overflow: "hidden" }}>
                        <p className="lead ckeditorNoMargin">
                            <CKEditor
                                editor={ InlineEditor }
                                data={customizableData.heroDesc}
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    console.log( { data } );
                                    setHeroDesc(data);
                                } }
                            />
                        </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid bg-light py-5" style={{
                backgroundSize: 'cover', backgroundPosition: 'bottom right',
                backgroundImage: 'url(http://themesmummy.com/mitech/static/130fe9ab91ac2fc94b9b15afd0b37809/8c24d/title-bar-01-bg.jpg)'
            }}>
                <div className="container">
                    <div className="row" style={{ borderBottom: "1px solid rgba(255,255,255,0.2)" }}>
                        <div className="col-md-4 text-dark ">
                            <h2 className="font-weight-light ckeditorNoMargin">
                                <CKEditor
                                editor={ InlineEditor }
                                data={customizableData.mainSectionHeading}
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    console.log( { data } );
                                    setMainSectionHeading(data);
                                } }
                                />
                            </h2>
                        </div>
                        <div className="col-md-6" style={{ overflow: "hidden" }}>
                                <CKEditor
                                editor={ InlineEditor }
                                data={customizableData.mainSectionDesc}
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    console.log( { data } );
                                    setMainSectionDesc(data);
                                } }
                                />
                        </div>
                    </div>


                </div>
            </div>
            <div className="container-fluid bg-light py-5" style={{}}>
                <div className="container">
                    {
                                sections.map((section, index) => {
                                    return(
                                    <div className="row" style={{ borderBottom: "1px solid rgba(255,255,255,0.2)" }}>
                                        <div className="col-md-4 text-dark ">
                                            <h2 className="font-weight-light ckeditorNoMargin">
                                                <CKEditor
                                                editor={ InlineEditor }
                                                data={section.sectionHeading}
                                                onChange={ ( event, editor ) => {
                                                    const data = editor.getData();
                                                    console.log( { data } );
                                                    sectionHandler("heading", data, index);
                                                } }
                                                />
                                            </h2>  
                                        </div>
                                        <div className="col-md-6" style={{ overflow: "hidden" }}>
                                                <CKEditor
                                                editor={ InlineEditor }
                                                data={section.sectionDesc}
                                                onChange={ ( event, editor ) => {
                                                    const data = editor.getData();
                                                    console.log( { data } );
                                                    sectionHandler("desc", data, index);
                                                } }
                                                />
                                        </div>
                                     </div>
                                    )
                                })
                    }
                    {/* <a href="#" style={{color: 'green'}} onClick={(e) => {e.preventDefault(); alert("clicked")}}><FontAwesomeIcon size="3x" icon={faPlusCircle} /></a> */}
                    <button variant="button" className="mr-2 btn btn-primary btn-sm" onClick={publishCustomizedPageHandler}>Publish</button>
                </div>
            </div>
                </>
                : null
            }
            <AcademyFooter />
        </>
    );
}

export default CustomizeAbout;
import React, { useCallback } from "react";
import { withRouter } from "react-router";
import firebase from "../../firebase";
import AcademyNavbar from './AcademyNavbar'


const SignUp = ({ history }) => {
  const handleSignUp = useCallback(async event => {
    event.preventDefault();
    const { email, password } = event.target.elements;
    const { firstName, lastName } = event.target.elements;
    try {
      await firebase
        .auth()
        .createUserWithEmailAndPassword(email.value, password.value)
        .then((cred) => {
            return firebase.firestore().collection('/Variants/pt-BR/academyUsers/').doc(cred.user.uid).set({
                firstName: firstName.value,
                lastName: lastName.value,
                email: email.value,
                hasAccess: false,
                canSeeOtherUsers: false,
                onlyLeadsAccess: false
            })
        })
        .then(() => {
            history.push("/");
        })
    } catch (error) {
      alert(error);
    }
  }, [history]);

  return (
      <>
      <AcademyNavbar />
        <div className="container text-white d-flex" style={{height: '100vh', alignItems: 'center'}}>
            <div className="row justify-content-center" style={{flex: '1'}}>
                <div className="col-12 col-md-8 col-lg-8 col-xl-6">
                    <h1>Sign up</h1>
                    <form onSubmit={handleSignUp}>
                        <div class="form-group">
                            <label for="inputFirstName">First Name</label>
                            <input type="text" name="firstName" class="form-control" id="inputFirstName"  placeholder="First Name" />
                        </div>
                        <div class="form-group">
                            <label for="inputLastName">Last Name</label>
                            <input type="text" name="lastName" class="form-control" id="inputLastName"  placeholder="Last Name" />
                        </div>
                        <div class="form-group">
                            <label className="m-0" for="inputEmail">Email address</label>
                            <small className="d-block mb-2 text-secondary">Use the same email you used while placing the order</small>
                            <input type="email" name="email" class="form-control" id="inputEmail" aria-describedby="emailHelp" placeholder="Enter email" />
                        </div>
                        <div class="form-group">
                            <label for="inputPassword">Password</label>
                            <input type="password" name="password" class="form-control" id="inputPassword" aria-describedby="passwordHelp" placeholder="Enter password" />
                        </div>
                        <div className="d-flex justify-content-around">
                            <button className="btn btn-primary" type="submit">Sign Up</button>
                            <button className="btn btn-light" type="button" onClick={() => {history.push('/login/')} }>Login</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
      </>
  );
};

export default withRouter(SignUp);
import React, { useEffect, useState } from 'react';
import { Card, Popover, OverlayTrigger, Button, Nav, NavDropdown, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import {
    Link, useHistory
} from "react-router-dom";
import AcademyNavbar from '../Academy/AcademyNavbar'
import AcademyFooter from '../Academy/AcademyFooter'
import firebase from 'firebase';
import background from '../../images/polygon.png'
import CKEditor from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';



function CustomizeTerms() {

    const history = useHistory();

    const [customizableData, setCustomizableData] = useState();
    const [termsDesc, setTermsDesc] = useState('');

    useEffect(() => {
        firebase.firestore().collection('/Variants/pt-BR/customizablePages/').doc('terms').get()
            .then((doc) => {
                if(doc.exists){
                    setCustomizableData(doc.data());
                    setTermsDesc(doc.data().termsDesc);
                }
            })
    },[]);

    const publishCustomizedPageHandler = () => {
        firebase.firestore().collection('/Variants/pt-BR/customizablePages/').doc('terms').update({
            termsDesc
        }).then(() => {
            alert('Page published!')
            history.push('/dashboard');
        })
    }


 

    return (
        <>
            <AcademyNavbar />
            {
                customizableData ? 
                <div className="container-fluid pt-5">
                <div className="container">
                    <div className="row py-4" style={{ borderBottom: "1px solid rgba(255,255,255,0.2)" }}>
                        <div className="col-md-10 text-white">
                        <p>
                            <CKEditor
                                editor={ InlineEditor }
                                data={customizableData.termsDesc}
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    console.log( { data } );
                                    setTermsDesc(data);
                                } }
                            />
                        </p>
                        <button variant="button" className="mr-2 btn btn-outline-light btn-sm" onClick={publishCustomizedPageHandler}>Publish</button>
                        </div>
                    </div>
                </div>
            </div>
            : null
            }
            <AcademyFooter />
        </>
    );
}

export default CustomizeTerms;
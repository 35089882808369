import React, { useEffect, useState } from 'react';
import { Card, Popover, OverlayTrigger, Button, Nav, NavDropdown, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import {
    Link, useHistory
} from "react-router-dom";
import AcademyNavbar from './AcademyNavbar'
import AcademyFooter from './AcademyFooter'
import firebase from 'firebase';
import background from '../../images/polygon.png'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


function Academy() {

    const history = useHistory();

    const [customizableData, setCustomizableData] = useState();
    const [refundDesc, setRefundDesc] = useState('');

    useEffect(() => {
        firebase.firestore().collection('/Variants/pt-BR/customizablePages/').doc('refund').get()
            .then((doc) => {
                if(doc.exists){
                    setCustomizableData(doc.data());
                    setRefundDesc(doc.data().refundDesc);
                }
            })
    },[]);

    return (
        <>
            <AcademyNavbar />
            {
                customizableData ?
                <div className="container-fluid pt-5">
                <div className="container">
                    <div className="row py-4" style={{ borderBottom: "1px solid rgba(255,255,255,0.2)" }}>
                        <div className="col-md-10 text-white">
                            {ReactHtmlParser(refundDesc)}
                        </div>
                    </div>
                </div>
            </div>
            : null
            }

            <AcademyFooter />
        </>
    );
}

export default Academy;
import React, { useEffect, useState } from 'react';
import { Card, Popover, OverlayTrigger, Button, Nav, NavDropdown, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import {
    Link, useHistory
} from "react-router-dom";
import AcademyNavbar from '../Academy/AcademyNavbar'
import AcademyFooter from '../Academy/AcademyFooter'
import firebase from 'firebase';
import background from '../../images/polygon.png'

import CKEditor from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import AddFileVariant from './AddFileVariant';

const categoryoptions = (
    <Popover id="popover-basic">
        <Popover.Title as="h3">Cateogry</Popover.Title>
        <Popover.Content>
            And here's some <strong>amazing</strong> content. It's very engaging.
        right?
      </Popover.Content>
    </Popover>
);

const Category = () => (
    <OverlayTrigger trigger="click" placement="bottom" overlay={categoryoptions}>
        <Button className="mb-5 mr-3 rounded small" variant="outline-light" style={{ borderColor: "#555555" }}>Category</Button>
    </OverlayTrigger>
);

const Dates = () => (
    <OverlayTrigger trigger="click" placement="bottom" overlay={categoryoptions}>
        <Button className="mb-5 mr-3 rounded small" variant="outline-light" style={{ borderColor: "#555555" }}>Dates</Button>
    </OverlayTrigger>
);

function CustomizeHome(props) {

    const handleChange = function (event) {
        console.log(event.target.value)
    }

    const history = useHistory();

    function handleClick() {
        history.push("/program");
    }

    const [programList, setProgramList] = useState([]);
    const [loadingProgramList, setLoadingProgramList] = useState(true);
    useEffect(() => {

        let programListRef = firebase.firestore().collection('/Variants/pt-BR/programList/');
        let allPrograms = programListRef.orderBy("position", "asc").get()
            .then(snapshot => {
                let tempProgramList = [];

                snapshot.forEach(doc => {
                    tempProgramList.push({ programSlug: doc.id, ...doc.data() });
                });
                setProgramList(tempProgramList);
                setLoadingProgramList(false);
                console.log(tempProgramList)
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
    }, [])


    const [customizableData, setCustomizableData] = useState();
    const [heroPhotoURL, setHeroPhotoURL] = useState('');
    const [heroHeading, setHeroHeading] = useState('');
    const [heroSubTitle, setHeroSubTitle] = useState('');
    const [heroPhotoCaptions, setHeroPhotoCaptions] = useState('');
    const [heroPointers, setHeroPointers] = useState([]);

    useEffect(() => {
        firebase.firestore().collection('/Variants/pt-BR/customizablePages/').doc('home').get()
            .then((doc) => {
                if(doc.exists){
                    setCustomizableData(doc.data());
                    setHeroHeading(doc.data().heroHeading);
                    setHeroSubTitle(doc.data().heroSubTitle);
                    setHeroPhotoURL(doc.data().heroPhotoURL);
                    setHeroPointers(doc.data().heroPointers);
                }
            })
    },[]);


    const heroPointersHandler = (type, data, index) => {
        const oldPointers = heroPointers;
        const oldPoint = heroPointers[index];
        if(type === "heading"){
            oldPoint.heading = data;
        }else{
            oldPoint.subTitle = data;
        }
        oldPointers.splice(index, 1, oldPoint);
        setHeroPointers(oldPointers);
    }

    const publishCustomizedPageHandler = () => {
        firebase.firestore().collection('/Variants/pt-BR/customizablePages/').doc('home').update({
            heroHeading,
            heroSubTitle,
            heroPhotoURL,
            heroPhotoCaptions,
            heroPointers
        }).then(() => {
            alert('Page published!')
            history.push('/dashboard');
        })
    }

    return (
        <>
            <AcademyNavbar />
            {
                customizableData ?
                <div className="container-fluid pt-5">
                <div className="container">
                    <div className="row mb-5">
                        {/* style={{ borderBottom: "1px solid rgba(255,255,255,0.2)" }} */}
                        <div className="col-md-6 text-white py-4 ">
                        <h1 className="font-weight-light mb-3 ckeditorNoPadding">
                            <CKEditor
                                editor={ InlineEditor }
                                data={customizableData.heroHeading}
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    console.log( { data } );
                                    setHeroHeading(data);
                                } }
                            />
                         </h1>
                            <p className="lead">
                                <CKEditor
                                    editor={ InlineEditor }
                                    data={customizableData.heroSubTitle}
                                    onInit={ editor => {
                                        // You can store the "editor" and use when it is needed.
                                        // console.log( 'Editor is ready to use!', editor );
                                    } }
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        console.log( { event, editor, data } );
                                        setHeroSubTitle(data);
                                    } }
                                    // onBlur={ ( event, editor ) => {
                                    //     console.log( 'Blur.', editor );
                                    // } }
                                    // onFocus={ ( event, editor ) => {
                                    //     console.log( 'Focus.', editor );
                                    // } }
                                />
                            </p>
                        </div>
                        <div className="col-md-6 text-white py-4">
                            <div className="w-100 rounded mb-2"
                                style={{
                                    backgroundImage: `url(${heroPhotoURL})`,
                                    backgroundSize: 'cover',
                                    height: '300px'
                                }}>
                                <AddFileVariant language={customizableData.lang} url={setHeroPhotoURL} imagePreview={false}/>
                            </div>
                            <p className="small">
                                <CKEditor
                                editor={ InlineEditor }
                                data={customizableData.heroPhotoCaptions}
                                onInit={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                    // console.log( 'Editor is ready to use!', editor );
                                } }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    console.log( { event, editor, data } );
                                    setHeroPhotoCaptions(data);
                                } }
                                // onBlur={ ( event, editor ) => {
                                //     console.log( 'Blur.', editor );
                                // } }
                                // onFocus={ ( event, editor ) => {
                                //     console.log( 'Focus.', editor );
                                // } }
                                />
                            </p>
                        </div>
                    </div>
                    <div className="row mb-5 text-white text-center">
                        {
                            customizableData.heroPointers.map((point, index) => {
                                return(
                                    <div className="col-md-3 mb-3 flex-grow-1">
                                        <div className="rounded border p-4 ckeditorNoPadding">
                                            <h5>
                                                <CKEditor
                                                    editor={ InlineEditor }
                                                    data={point.heading}
                                                    onInit={ editor => {
                                                        // You can store the "editor" and use when it is needed.
                                                        // console.log( 'Editor is ready to use!', editor );
                                                    } }
                                                    onChange={ ( event, editor ) => {
                                                        const data = editor.getData();
                                                        console.log( { event, editor, data } );
                                                        heroPointersHandler("heading", data, index);
                                                    } }
                                                    // onBlur={ ( event, editor ) => {
                                                    //     console.log( 'Blur.', editor );
                                                    // } }
                                                    // onFocus={ ( event, editor ) => {
                                                    //     console.log( 'Focus.', editor );
                                                    // } }
                                                />
                                            </h5>
                                            <p className="mb-0">
                                                <CKEditor
                                                    editor={ InlineEditor }
                                                    data={point.subTitle}
                                                    onInit={ editor => {
                                                        // You can store the "editor" and use when it is needed.
                                                        // console.log( 'Editor is ready to use!', editor );
                                                    } }
                                                    onChange={ ( event, editor ) => {
                                                        const data = editor.getData();
                                                        console.log( { event, editor, data } );
                                                        heroPointersHandler("subTitle", data, index);
                                                    } }
                                                    // onBlur={ ( event, editor ) => {
                                                    //     console.log( 'Blur.', editor );
                                                    // } }
                                                    // onFocus={ ( event, editor ) => {
                                                    //     console.log( 'Focus.', editor );
                                                    // } }
                                                />
                                            </p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
                : null
            }
            <div className="container-fluid bg-black pt-5">
                <div className="container">
                    {loadingProgramList
                        ? <div className="container-fluid w-100 d-flex justify-content-center align-items-center" style={{ height: "20vh" }}>
                            <Spinner animation="border" role="status" variant="light">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div>
                        : <div className="row">
                            {programList.map((item, key) => {
                                if(+item.position >= 0){
                                    return(
                                            <div className="col-md-4" key={key}>
                                                <Link className="link" to={`/program/${item.programSlug}`}>
                                                    <Card className="mb-3 border-0" bg="transparent" text="white">
                                                        <Card.Img variant="top" src={item.programThumbnail} />
                                                        <Card.Body className="px-0">
                                                            <span className="small mb-4 text-warning">STARTS OCTOBER 2020</span>
                                                            <Card.Title>{item.programTitle}</Card.Title>
                                                            <Card.Text className="small">{item.programSubTitle}</Card.Text>
                                                            <div className="row">
                                                                <div className="col-12 ">
                                                                    {item.associatedUniversities && <div>
                                                                        <p className="small font-weight-bold text-muted pt-3 border-top">Learn and interact through live online classes with world class faculty from</p>
                                                                        <div className="row">
                                                                            {item.associatedUniversities.map(university =>
                                                                                <p className="col-md-6 mb-1 small ">{university}</p>
                                                                            )}
                                                                        </div>
                                                                    </div>}
                                                                </div>
                                                                <div className="col-12">
                                                                 <p className="small mt-3">Attested with a record of attendance from the <strong>University of California, Los Angeles (UCLA) Extension</strong></p>
                                                                 <img className="mb-3" 
                                                                    src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fucla_reverse.png?alt=media&token=adbdeab7-41e8-4d9a-a1a7-9f2d8fb3f5ac" 
                                                                 width="30%" />
                                                                </div>
                                                            </div>
                                                            <span className="text-muted">Learn More <FontAwesomeIcon icon={faArrowRight} size="sm" color="grey" /></span>
                                                        </Card.Body>
                                                    </Card>
                                                </Link>
                                            </div>
                                    )
                                }
                            })}
                        </div>}
                        <button variant="button" className="mr-2 btn btn-outline-light btn-sm" onClick={publishCustomizedPageHandler}>Publish</button>
                </div>
            </div>
            <AcademyFooter />
        </>
    );
}

export default CustomizeHome;
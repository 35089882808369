import React from 'react';
import firebase from 'firebase/app';
import {db} from '../../firebase';
import {storageRef} from '../../firebase';
import Modal from 'react-modal';
import moment from 'moment'
import 'filepond/dist/filepond.min.css';
import { FilePond } from 'react-filepond';
import { Container } from 'react-bootstrap';


Modal.setAppElement('#root')

class FacultyCRUD extends React.Component {
  state = { 
    selectTitle:'', editSelectTitle:'',
    firstName:'',   editFirstName:'',
    middleName:'',  editMiddleName:'',
    lastName:'',    editLastName:'',
    gender:'',      editGender:'',
    dob:'',         editDOB:'',
    description:'', editDescription:'',
    languages:[],   editLanguages:[],
    mailInput:[{mail:''}],  editMailInput:[{mail:''}],
    eduInput:[{course:'', college:'', startDate:'', endDate:''}],
    editEducation:[{course:'', college:'', startDate:'', endDate:''}],
    expInput:[{designation:'', organisation:'', startDate:'', endDate:''}],
    editExperience:[{designation:'', organisation:'', startDate:'', endDate:''}],
    image:[], editImg:[],
    url:'', editUrl:'',
    docs: [], 
    isEditIndex: null, 
    currentEdit: '', 
    isEdit:false ,
    modelOpen:'',
    date: moment().format("YYYY-MM"),
    dobDateMax: moment().format("YYYY-MM-DD")
  }

  componentDidMount() {
     db.collection("faculty").onSnapshot(snapshot => {
      let changes = snapshot.docChanges();
      changes.forEach(change => {
       
        const doc = change.doc;
        const id = change.doc.id;
        const selectTitle = change.doc.data().selectTitle;
        const fullName = change.doc.data().fullName;
        const description = change.doc.data().description;
        const languages = change.doc.data().languages;
        const education = change.doc.data().education;
        const experience = change.doc.data().experience;
        const profilePic = change.doc.data().profilePicUrl;
        const email = change.doc.data().email;
        const gender =change.doc.data().gender;
        const dob = change.doc.data().dob
        
       // console.log(education)
        if (change.type === 'added') {
          this.setState({ docs: [...this.state.docs, {fullName, id ,description, languages, education, experience, profilePic, email, gender,dob }] })
        } else if (change.type === 'removed') {
          var temp = this.state.docs
          temp = temp.filter((docs) => docs.id != id)
          this.setState({ docs: temp })
        }
      })
    })
  }

  eMailAddField = (e) => {
    e.preventDefault();
    var values = [...this.state.mailInput];
    values.push({mail:''})
    this.setState({mailInput:values})
  }
  eMailRemoveField = (e, index) => {
    e.preventDefault();
    var values = [...this.state.mailInput];
    values.splice(index, 1);
    this.setState({mailInput:values})
  }

   educationAddField = (e) => {
     e.preventDefault()
    const values = [...this.state.eduInput];
    values.push({ course: '', college: '',startDate:'', endDate:'' });
    this.setState({eduInput:values})
  };

  educationRemoveField = (e, index) => {
    e.preventDefault()
    const values = [...this.state.eduInput];
    values.splice(index, 1);
   this.setState({eduInput:values})
  }

  experienceAddField = (e) => {
    e.preventDefault()
   const values = [...this.state.expInput];
   values.push({ designation: '', organisation: '',startDate:'', endDate:'' });
   this.setState({expInput:values})
 };

 experienceRemoveField = (e, index) => {
   e.preventDefault()
   const values = [...this.state.expInput];
   values.splice(index, 1);
  this.setState({expInput:values})
 }

 handleMailInputChange = (index, e) => {
   e.preventDefault()
   var values =[...this.state.mailInput];
   if(e.target.name === "mail"){
     values[index].mail =e.target.value;
   }
   this.setState({mailInput:values})
 }

  handleEduInputChange = (index, e) => {
    e.preventDefault()
    const values = [...this.state.eduInput];
    if (e.target.name === "course") {
      values[index].course = e.target.value;
    } else if(e.target.name==="college") {
      values[index].college = e.target.value;
    } else if(e.target.name==="start_date"){
      values[index].startDate = e.target.value;
    } else {
      values[index].endDate = e.target.value;
    }
      this.setState({eduInput:values})
    //setInputFields(values);
  };
  editHandleEduInputChange = (index, e) => {
    e.preventDefault()
    var values = [...this.state.editEducation];
    if (e.target.name === "course") {
      values[index].course = e.target.value;
    } else if(e.target.name==="college") {
      values[index].college = e.target.value;
    } else if(e.target.name==="start_date"){
      values[index].startDate = e.target.value;
    } else {
      values[index].endDate = e.target.value;
    }
      this.setState({editEducation:values})
  }
  handleExpInputChange = (index,event) => {
    event.preventDefault()
    const value = [...this.state.expInput];
    if (event.target.name === "designation") {
      value[index].designation = event.target.value;
    } else if(event.target.name==="organisation") {
      value[index].organisation = event.target.value;
    } else if(event.target.name==="start_date"){
      value[index].startDate = event.target.value;
    } else {
      value[index].endDate = event.target.value;
    }
      this.setState({expInput:value})
  }
  editHandleExpInputChange = (index, event) => {
    event.preventDefault()
    const value = [...this.state.editExperience];
    if (event.target.name === "designation") {
      value[index].designation = event.target.value;
    } else if(event.target.name==="organisation") {
      value[index].organisation = event.target.value;
    } else if(event.target.name==="start_date"){
      value[index].startDate = event.target.value;
    } else {
      value[index].endDate = event.target.value;
    }
      this.setState({editExperience:value})
  }

 
  checkboxHandle = (event) => {
    if(event.target.checked){
      this.setState({languages:[...this.state.languages, event.target.value]})
    }}
    editCheckboxHandle = (event) => {
      if(event.target.checked){
        this.setState({editLanguages:[...this.state.editLanguages, event.target.value]})
      }
    }

uploadHandle = (e) => {
  e.preventDefault()
var that = this
  //console.log(this.state.url,'below that')
var file = this.state.image;
var metadata = {contentType: 'image/jpeg' };
var uploadTask = storageRef.child('faculty/' + file.name).put(file, metadata);
  uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
  snapshot => {
  var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
             case firebase.storage.TaskState.PAUSED:
               console.log('Upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING: 
                console.log('Upload is running');
               break;
           }
          },error => {
            switch (error.code){
              case 'storage/unauthorized':
                break;
                case 'storage/canceled':
                  break;
                  case 'storage/unknown':
                    break;
            }},
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL){
             console.log('File available at', downloadURL);
                that.setState({url:downloadURL})
                console.log('url',that.state.url)
            });
          });
}


  submit = (e) => {
    e.preventDefault();
     

  //console.log("url",this.state.url)
   if(this.state.middleName===''){
    const fullname = {selectTitle:this.state.selectTitle,firstName:this.state.firstName, lastName:this.state.lastName}
    db.collection('faculty').add({
      fullName:fullname,
      gender:this.state.gender,
      dob:this.state.dob,
      email:this.state.mailInput,
      description:this.state.description,
      languages:this.state.languages,
      education:this.state.eduInput,
      experience:this.state.expInput,
      profilePicUrl:this.state.url
    }).then(() => this.setState({selectTitle:'', firstName: '', middleName:'', lastName: '' , description:'', languages:[], gender:'', dob:'', image:[],
     education:{course:'', college:'', startDate:'', endDate:''}, experience:{designation:'', organisation:'', startDate:'', endDate:'' }}))
   }else{
     const fullName = {selectTitle:this.state.selectTitle, firstName:this.state.firstName, middleName:this.state.middleName, lastName:this.state.lastName,  languages:[],}
     console.log('under url',this.state.url)
     db.collection('faculty').add({
      fullName:fullName,
      gender:this.state.gender,
      dob:this.state.dob,
      email:this.state.mailInput,
      description:this.state.description,
      languages:this.state.languages,
      education:this.state.eduInput,
      experience:this.state.expInput,
      profilePicUrl:this.state.url
      
    }).then(() => this.setState({selectTitle:'',fullName:{ firstName: '', middleName:'', lastName: '' }, description:'', languages:[], gender:'', dob:'', image:[],
     education:{course:'', college:'', date:''}, experience:{designation:'', organisation:'', startDate:'', endDate:'' }}))
   }
   alert("Added Successfully")
   
  }


  delData = (id, profileurl) => {
    db.collection('faculty').doc(id).delete().then(() => {
      var deleteRef = firebase.storage().refFromURL(profileurl)
    deleteRef.delete().then(() => {
      console.log('deleted successfully')
    }).catch((error) => {
     console.log('error')
    })
    })
    
  }


  isEdit = (doc, index) => {
    const { docs } = this.state;
    this.setState({editSelectTitle:doc.fullName.selectTitle, editFirstName:doc.fullName.firstName, editMiddleName:doc.fullName.middleName, editLastName:doc.fullName.lastName, editGender:doc.gender, editDOB:doc.dob, editDescription:doc.description, editLanguages:doc.languages, editUrl:doc.profilePic, editMailInput:doc.email,editEducation:doc.education, editExperience:doc.experience })
    this.setState({
      editFname: docs[index].firstname,
      editMname:docs[index].middlename,
      editLname: docs[index].lastname,
      isEdit: true,
      isEditIndex: doc.id
    })
  }
  editUpdateProfilePic = (e) => {
  
  }

  editImgHandle = (e) => {
    e.preventDefault()
    var that = this
      //console.log(this.state.url,'below that')
    var file = this.state.editImg;
    var metadata = {contentType: 'image/jpeg' };
    var uploadTask = storageRef.child('faculty/' + file.name).put(file, metadata);
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      snapshot => {
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                 case firebase.storage.TaskState.PAUSED:
                   console.log('Upload is paused');
                    break;
                  case firebase.storage.TaskState.RUNNING: 
                    console.log('Upload is running');
                   break;
               }
              },error => {
                switch (error.code){
                  case 'storage/unauthorized':
                    break;
                    case 'storage/canceled':
                      break;
                      case 'storage/unknown':
                        break;
                }},
              () => {
                uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL){
                 console.log('File available at', downloadURL);
                    that.setState({editUrl:downloadURL})
                });
              });
  }

  editData = (e, id, index) => {
   const { docs,  editDescription, editLanguages, editEducation, editExperience} = this.state;
    let allDocs = [...docs];
     e.preventDefault();
           
    if(this.state.editMiddleName===''|| this.state.editMiddleName === undefined){
      const editFullName = {selectTitle:this.state.editSelectTitle, firstName:this.state.editFirstName, lastName:this.state.editLastName}
      let editedData = {id: id, fullName: editFullName, description: this.state.editDescription, languages:this.state.editLanguages, education:this.state.editEducation, experience:this.state.editExperience, email:this.state.editMailInput };
      allDocs[index] = editedData;
    db.collection('faculty').doc(id).update({
     fullName:editFullName,
     description:this.state.editDescription,
     languages:this.state.editLanguages,
     dob:this.state.editDOB,
     email:this.state.editMailInput,
     education:this.state.editEducation,
     experience:this.state.editExperience,
     profilePicUrl:this.state.editUrl
    }).then(()=>{
      this.setState({
        docs: allDocs,
        isEdit: false,
        isEditIndex: null,
        
      })
    })}else{
      const editFullName = {selectTitle:this.state.editSelectTitle, firstName:this.state.editFirstName, middleName:this.state.editMiddleName, lastName:this.state.editLastName}
      let editedData = {id: id, fullName: editFullName, description: this.state.editDescription, languages:this.state.editLanguages, education:this.state.editEducation, experience:this.state.editExperience };
      allDocs[index] = editedData;
      console.log(editFullName)
      db.collection('faculty').doc(id).update({
        fullName:editFullName,
        description:this.state.editDescription,
        languages:this.state.editLanguages,
        dob:this.state.editDOB,
        email:this.state.editMailInput,
        education:this.state.editEducation,
        experience:this.state.editExperience,
        profilePicUrl:this.state.editUrl
       }).then(()=>{
         this.setState({
           docs: allDocs,
           isEdit: false,
           isEditIndex: null,
           
         })
       })
    }
    alert("Updated Successfully")
   }


  render() {
    return (
      <div>
        <div>
          <div className="text-center text-white">
            <div><img alt="igesia logo" src="https://firebasestorage.googleapis.com/v0/b/igesia-dbadmin.appspot.com/o/faculty%2Funnamed.png?alt=media&token=32746652-7be7-4ed6-a7f6-596023775188" /></div>
            
            <br/>
            <form className='form-group' onSubmit={this.submit} style={{display:'inline-block'}}>
              
              
            <div className='d-flex justify-content-around'>
                  <div > 
                      <label>Title :-</label><br/>
                      <select className="btn btn-secondary " 
                        value={this.state.selectTitle} 
                        onChange={event => this.setState({selectTitle:event.target.value})}>
                          <option>Select Title</option>
                          <option value='Prof'>Prof</option>
                          <option value='Dr'>Dr</option>
                          <option value='Mr.'>Mr</option>
                          <option value='Ms'>Ms</option>
                          <option value='Mrs'>Mrs</option>
                          <option value='Smt'>Smt</option>
                          <option value='Shri'>Shri</option>
                      </select>
                  </div>

                  <div className='' >
                      <label>Date of Birth:-</label>
                        <input className='form-control' type='date' placeholder='DOB' name='dob' 
                          max={this.state.dobDateMax}
                          value={this.state.dob}
                          onChange={event => this.setState({dob:event.target.value})}/>
                    </div>

                  <div className=''  >
                      <label>Gender:-</label><br/>
                      <select className="btn btn-secondary " 
                        value={this.state.gender} 
                        onChange={event => this.setState({gender:event.target.value})}>
                          <option>Select Gender</option>
                          <option value='Male'>Male</option>
                          <option value='Female'>Female</option>
                          <option value='Others'>Others</option>
                      </select>
                  </div>
            </div><br/><br/>

            <div className="d-flex justify-content-around">
              <div className='col'>
                <label>FirstName :-</label><br/>
                  <input className='form-control' placeholder='Enter your First Name'
                  onChange={event => this.setState({firstName: event.target.value })}
                  value={this.state.firstName}
                /><br />
              </div>

              <div className='col'>
                <label>MiddleName :-</label><br/>
              <input className='form-control' placeholder='Enter your Middle Name'  
                onChange={event => {this.setState({middleName:event.target.value}) }}
                value={this.state.middleName}
                /><br />
              </div>

              <div className='col'>
                <label>LastName :-</label><br/>
                  <input className='form-control' placeholder='Enter your Last Name'
                    onChange={event => this.setState({lastName: event.target.value})}
                    value={this.state.lastName}/><br />
              </div>
            </div>  <br/>
              
            <div className=''>  
              <div className='row'>
                <label> Description :-</label><br/>
                  <textarea className='form-control' placeholder='About yourself (In English)'
                  onChange={event => this.setState({description:event.target.value})}
                  value={this.state.description}
                  />
              </div>
            </div><br/>

            <div className=''>  
              <div className='row'>
                <label> Description :-</label><br/>
                  <textarea className='form-control' placeholder='About yourself (In Portugese)'
                  onChange={event => this.setState({description:event.target.value})}
                  value={this.state.description}
                  />
              </div>
            </div><br/>
              

          <div className='d-flex justify-content-around'>    
            <div className='col-md-6'>
                <label  >Upload Profile pic:- </label>
                <FilePond  
                allowMultiple={false}
                onupdatefiles={fileItems => fileItems.map((fileItem) => this.setState({image:fileItem.file}))}></FilePond>
                <button className='btn btn-primary' onClick={this.uploadHandle}>Upload</button><br/><br/>
            </div>

            
              <div ><br/>
                  <label >Languages:- &nbsp; &nbsp;</label>
                  <input id='checkbox1' type='checkbox' value='English' name='English' onChange={this.checkboxHandle}/>English &nbsp;
                  <input id='checkbox2' type='checkbox' value='Hindi' name='Hindi'  onChange={this.checkboxHandle}/>Hindi &nbsp;
                  <input id='checkbox3' type='checkbox' value='Spanish' name='Spanish'  onChange={this.checkboxHandle}/>Spanish &nbsp;
                  <input id='checkbox4' type='checkbox' value='German' name='German'  onChange={this.checkboxHandle}/>German &nbsp;
              </div>
          </div>    

            {this.state.mailInput.map((mailInput,index) => (
              <div key={index}>
                <div className="d-flex align-items-center">
                <label>Email:-</label>
                  <input className='form-control' type='text' placeholder='Email' name='mail'
                    value={this.state.mailInput.mail} onChange={event => this.handleMailInputChange(index, event)} />
              <button className='btn btn-primary' onClick={(e) => this.eMailAddField(e)}>+</button>
              <button className='btn btn-primary' onClick={(e)=> this.eMailRemoveField(e,index)} >-</button>
                </div>
              </div>
            ))}
            <br/>
        { this.state.eduInput.map((eduInput,index) =>  (
        <div className='form-inline' key={index}>
            <label>Education :-</label><br/>
              <div className='form-inline'>
              <div className='form-col'>
              <input className='form-control' type='text' placeholder='Course' name='course'
              value={this.state.eduInput.course}
              onChange={event => this.handleEduInputChange(index, event)}
              /></div> <div className='form-col'>
              <input className='form-control' type='text' placeholder='College' name='college'
               onChange={event => this.handleEduInputChange(index, event)}
             // onChange={event => this.setState({education:{course:this.state.education.course, college:event.target.value}})}
              value={this.state.eduInput.college}
              /></div> 
              
              <div className='form-col'>
              <label>Start Date</label>
              <input className='form-control' type='month'  placeholder='Start Date'  name='start_date'
              max={this.state.date}
              value={this.state.eduInput.startDate} 
              onChange={event => this.handleEduInputChange(index, event)}
              /></div> <div className='form-col'>

              <label>End Date</label>
              <input className='form-control' type='month' placeholder='End Date' name='End Date'
              max={this.state.date}
              value={this.state.eduInput.endDate}
              onChange={event => this.handleEduInputChange(index, event)}
              />
              
              <button className='btn btn-primary' onClick={(e) => this.educationAddField(e)}>+</button>
              <button className='btn btn-primary' onClick={(e)=> this.educationRemoveField(e,index)} >-</button>
              </div></div></div>
         ) ) },
              <br/><br/>

           
              {this.state.expInput.map((expInput,index) => (
              <div className='form-inline' key={index}>
                <label>Experience :-</label><br/><br/>
              <div className='form-row form-inline'>
                <div className='form-col'>
                  <input className='form-control' type='text' placeholder='Designation'  name='designation'
                    value={this.state.expInput.designation}
                    onChange={event => this.handleExpInputChange(index, event)}
                    />
                </div> 
                <div className='form-col'>
                  <input className='form-control' type='text' placeholder='Organisation' name='organisation'
                   value={this.state.expInput.organisation}
                   onChange={event => this.handleExpInputChange(index, event)}
                  /></div> 
              <div className='form-col'>
              <label>Start Date</label>
              <input className='form-control' type='month' placeholder='Exp Start Date' name='start_date' 
              max={this.state.date}
               value={this.state.expInput.startDate}
               onChange={event => this.handleExpInputChange(index, event)}
              /></div> <div className='form-col'>
              <label>End Date</label>
              <input className='form-control' type='month' placeholder='Exp End Date'  name='end_date'
               value={this.state.expInput.endDate}
               max={this.state.date}
               onChange={event => this.handleExpInputChange(index, event)}
              /><br/></div> 
              <button className='btn btn-primary' onClick={(e) => this.experienceAddField(e)}>+</button>
              <button className='btn btn-primary' onClick={(e)=> this.experienceRemoveField(e,index)} >-</button>
              </div>
              </div>
               )) }
            <br/>

            
           
              <button className="btn btn-success btn-lg">Submit</button>
            </form>
          </div>
          
        
        
      <Container>
          <table className='table table-bordered text-white'>
            <thead className='thead-dark'>
              <tr>
                <th scope='col'>S.no</th>
                <th scope='col'>Profile Picture</th>
                <th scope='col'>FirstName</th>
                
                <th scope='col'>LastName</th>
                <th scope='col'>Description</th>
                
                
                <th scope='col'>Action</th>
              </tr>
            </thead>

            {this.state.docs.map((doc,index) => (
              <tbody key={doc.id}>
               <tr>
                    <th scope='row'>{index+1}</th>
                        <td><img style={{width:'80%'}} alt="profile picture" className='rounded' src={doc.profilePic} /></td>
                        <td>{doc.fullName.firstName}</td>
                        <td>{doc.fullName.lastName}</td>
                       <td>{doc.description}</td>
                  
                       <td><button className="btn btn-secondary" onClick={() => this.setState({modelOpen:doc.id})}>View Faculty</button>
                        
                        
                          <button className="btn btn-danger" onClick={() => this.delData(doc.id, doc.profilePic)}>Delete</button>
                        </td>
                      
                    </tr>   
                  
                 </tbody>
            ))
            }
          </table>
        </Container>

          {   this.state.docs.map((docs,index) => (
          <div>
            { (this.state.modelOpen === docs.id) ? (
                     <Modal isOpen={this.state.modelOpen===docs.id} onRequestClose={() => this.setState({modelOpen:''})}>
                     <div>

                      {(this.state.isEdit === false || this.state.isEditIndex !== docs.id) ? (
                       <div key={docs.id}>
                       <div >
                         <div><img className='rounded mx-auto d-block' src={docs.profilePic} /></div><br/><br/>
                      <div className='mx-auto blockquote text-center'>FullName :-{docs.fullName.selectTitle} {docs.fullName.firstName} {docs.fullName.middleName} {docs.fullName.lastName}</div>
                       </div>
                        <div key={docs.id} className='text-center'><label className='blockquote'>Description:-</label>{docs.description}</div>
                        <div className='text-center'><label className='blockquote'>Languages:- </label>{docs.languages}</div>
                        <div>{ (!docs.gender) ? (null):(<div className='text-center'><label>Gender:-</label>{docs.gender}</div>)}</div>
                       <div><br/>

                      <div>{ (!docs.dob) ? (null):(<div className='text-center'><label>DOB:-</label>{docs.dob}</div>)}</div>
                       
                        
                      {docs.email.map((email, index) => (<div key={index}>{(email.mail==='') ? (null):(<div className='text-center'><label>Email:-</label> <div>{email.mail}</div></div>)}</div>))}
                        {docs.education.map((education, index) => ( 
                          <div key={index}>
                            { (!education.course)  ? (null):(
                              
                              <div className='text-center'>
                            <label className='blockquote'>Education</label>
                            <div>Course:- {education.course}</div>
                            <div>College:- {education.college}</div>
                            <div>Start Date:- {education.startDate}</div>
                            <div>End Date:- {education.endDate}</div>
                            </div>
                            )  }   
                          </div>
                        ))}
                          <br/>
                          <div>
                            
                          {docs.experience.map((experience, index) => (
                          <div  className='text-justify'>
                            { (!experience.designation) ? (null): (
                            <div className='text-center'>
                             <label>Experience</label>
                            <div>Designation:- {experience.designation}</div>
                            <div>Organisation:- {experience.organisation}</div>
                            <div>Start Date:- {experience.startDate}</div>
                            <div>End Date:- {experience.endDate}</div>
                          </div>)}
                          </div>))}
                        </div>
                        <div className='text-center'>
                       <button className="btn btn-secondary" onClick={() => this.isEdit(docs, index)}>Edit</button> &nbsp; &nbsp;
                      <button className='btn btn-primary' onClick={() => this.setState({modelOpen:''})}>Close</button>
                      </div>
                       </div></div>):(
                       <div>




                        <div>
                      
                        <div><input type='file' defaultValue={this.state.image[0]} 
                        onChange={event => {this.setState({editImg:event.target.files[0]})}} />
                        <button className='btn btn-primary' onClick={this.editImgHandle}>Upload</button>
                        </div>
                        <div>
                        <div>
                          <label>FirstName</label>
                          <input 
                          required 
                          type='text' 
                          defaultValue={docs.fullName.firstName}
                          onChange={event => this.setState({editFirstName: event.target.value})} />
                        </div>
                        <div>
                          <label>MiddleName</label>
                        <input type='text' 
                          defaultValue={docs.fullName.middleName}
                          onChange={event => this.setState({ editMiddleName: event.target.value })} />
                         </div>
                        <div> 
                          <label>LastName</label>
                          <input type='text' 
                          defaultValue={docs.fullName.lastName}
                          onChange={event => this.setState({ editLastName: event.target.value })} />
                        </div>
                        </div>

                        <div>
                          <label>Description</label>
                          <input   required type='text' 
                          defaultValue={docs.description}
                          onChange={event => this.setState({editDescription:event.target.value })} />
                        </div>

                        <div>
                          <label>Date of Birth :-</label>
                          <input type='date' defaultValue={docs.dob} 
                          onChange={event => this.setState({editDOB:event.target.value})}
                          />
                        </div>

                        <div >
                          <label>Gender:-</label>
                            <select className="btn btn-secondary dropdown-toggle" 
                              value={this.state.gender} 
                              onChange={event => this.setState({editGender:event.target.value})}>
                              <option>Select Gender</option>
                              <option value='Male'>Male</option>
                              <option value='Female'>Female</option>
                              <option value='Others'>Others</option>
                            </select>
                        </div>

                        <div>
                          <label>Email :-</label>
                          {docs.email.map((email, index) => (
                          <input key={index} type='email' defaultValue={email.mail} 
                          onChange={(event) => {
                            var value =[...this.state.editMailInput];
                            value[index].mail = event.target.value;
                            this.setState({editMailInput:value})}}
                          />))}
                        </div>


                  { docs.education.map((education,index) =>  (
                    <div className='form-inline' key={index}>
                        <label>Education :-</label><br/>
                          <div className='form-inline'>
                          <div className='form-col'>
                          <input className='form-control' type='text' placeholder='Course' name='course'
                          defaultValue={education.course}
                          onChange={event => this.editHandleEduInputChange(index, event)}
                          /></div> <div className='form-col'>
                          <input className='form-control' type='text' placeholder='College' name='college'
                          onChange={event => this.editHandleEduInputChange(index, event)}
                        
                          defaultValue={education.college}
                          /></div> 
                          
                          <div className='form-col'>
                          <label>Start Date</label>
                          <input className='form-control' type='month'  placeholder='Start Date'  name='start_date'
                          max={this.state.date}
                          defaultValue={education.startDate} 
                          onChange={event => this.editHandleEduInputChange(index, event)}
                          /></div> <div className='form-col'>

                          <label>End Date</label>
                          <input className='form-control' type='month' placeholder='End Date' name='End Date'
                          max={this.state.date}
                          defaultValue={education.endDate}
                          onChange={event => this.editHandleEduInputChange(index, event)}
                          />
                          

                          </div></div></div>
                    ) ) }
              <br/>


                          {docs.experience.map((experience,index) => (
                          <div className='form-inline' key={index}>
                            <label>Experience :-</label><br/><br/>
                          <div className='form-row form-inline'>
                            <div className='form-col'>
                              <input className='form-control' type='text' placeholder='Designation'  name='designation'
                                defaultValue={experience.designation}
                                onChange={event => this.editHandleExpInputChange(index, event)}
                                />
                            </div> 
                            <div className='form-col'>
                              <input className='form-control' type='text' placeholder='Organisation' name='organisation'
                              defaultValue={experience.organisation}
                              onChange={event => this.editHandleExpInputChange(index, event)}
                              /></div> 
                          <div className='form-col'>
                          <label>Start Date</label>
                          <input className='form-control' type='month' placeholder='Exp Start Date' name='start_date' 
                          max={this.state.date}
                          defaultValue={experience.startDate}
                          onChange={event => this.editHandleExpInputChange(index, event)}
                          /></div> <div className='form-col'>
                          <label>End Date</label>
                          <input className='form-control' type='month' placeholder='Exp End Date'  name='end_date'
                          defaultValue={experience.endDate}
                          max={this.state.date}
                          onChange={event => this.editHandleExpInputChange(index, event)}/><br/>
                          </div> 
                          </div>
                          </div>
                          )) }
                        <br/>
                        


                      <div>
                          <button className='btn btn-secondary' onClick={(e) => this.editData(e, docs.id, index)}>Update</button>
                     </div>  
                        <div>
                        <button className='btn btn-primary' onClick={() => this.setState({modelOpen:''})}>Close</button>
                        </div>
                       </div>




                      
                      
                       </div> ) }
                       </div>
                        
                     </Modal>  
                     
                     ):(null)
              }         
       </div>
          ))}
        </div>
        
      </div>
    );
  }
}

export default FacultyCRUD;
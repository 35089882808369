import React, { useEffect, useState } from 'react';
import {
    Card,
    Button,
    Image,
    Spinner,
    Modal,
    Alert,
    Badge
} from 'react-bootstrap';
import AcademyFooter from '../Academy/AcademyFooter';
import firebase from 'firebase';
import DashboardNavbar from '../Dashboard/DashboardNavbar';
import {
    Link
  } from "react-router-dom";


const Customize = (props) => {
    return (
        <>
            <DashboardNavbar />
            <div className="container mt-3">
                <div className="row">
                <div className="col-lg-2 col-md-4 mb-5 mb-lg-3">
                <Link to="/admin/customize/home" style={{ textDecoration: 'none' }}>
                <Card className="bg-primary text-white card-body d-flex align-items-end flex-column text-right class h-100" style={{ padding: '1.25rem'}}>
                    {/* <Card.Img variant="top" src={post.data.imageUrl} /> */}
                    <Card.Title className="font-weight-light p-3">Home Page</Card.Title>
                    <Card.Text>
                    </Card.Text>
                </Card>
                </Link>
                </div>
                <div className="col-lg-2 col-md-4 mb-5 mb-lg-3">
                <Link to="/admin/customize/about" style={{ textDecoration: 'none' }}>
                <Card className="bg-primary text-white card-body d-flex align-items-end flex-column text-right class h-100" style={{ padding: '1.25rem'}}>
                    {/* <Card.Img variant="top" src={post.data.imageUrl} /> */}
                    <Card.Title className="font-weight-light p-3">About Page</Card.Title>
                    <Card.Text>
                    </Card.Text>
                </Card>
                </Link>
                </div>
                <div className="col-lg-2 col-md-4 mb-5 mb-lg-3">
                <Link to="/admin/customize/privacy" style={{ textDecoration: 'none' }}>
                <Card className="bg-primary text-white card-body d-flex align-items-end flex-column text-right class h-100" style={{ padding: '1.25rem'}}>
                    {/* <Card.Img variant="top" src={post.data.imageUrl} /> */}
                    <Card.Title className="font-weight-light p-3">Privacy Page</Card.Title>
                    <Card.Text>
                    </Card.Text>
                </Card>
                </Link>
                </div>
                <div className="col-lg-2 col-md-4 mb-5 mb-lg-3">
                <Link to="/admin/customize/terms" style={{ textDecoration: 'none' }}>
                <Card className="bg-primary text-white card-body d-flex align-items-end flex-column text-right class h-100" style={{ padding: '1.25rem'}}>
                    {/* <Card.Img variant="top" src={post.data.imageUrl} /> */}
                    <Card.Title className="font-weight-light p-3">Terms Page</Card.Title>
                    <Card.Text>
                    </Card.Text>
                </Card>
                </Link>
                </div>
                <div className="col-lg-2 col-md-4 mb-5 mb-lg-3">
                <Link to="/admin/customize/refund" style={{ textDecoration: 'none' }}>
                <Card className="bg-primary text-white card-body d-flex align-items-end flex-column text-right class h-100" style={{ padding: '1.25rem'}}>
                    {/* <Card.Img variant="top" src={post.data.imageUrl} /> */}
                    <Card.Title className="font-weight-light p-3">Refund Page</Card.Title>
                    <Card.Text>
                    </Card.Text>
                </Card>
                </Link>
                </div>
                <div className="col-lg-2 col-md-4 mb-5 mb-lg-3">
                <Link to="/admin/customize/contact" style={{ textDecoration: 'none' }}>
                <Card className="bg-primary text-white card-body d-flex align-items-end flex-column text-right class h-100" style={{ padding: '1.25rem'}}>
                    {/* <Card.Img variant="top" src={post.data.imageUrl} /> */}
                    <Card.Title className="font-weight-light p-3">Contact Page</Card.Title>
                    <Card.Text>
                    </Card.Text>
                </Card>
                </Link>
                </div>
                </div>
            </div>
            <AcademyFooter />
        </>
    );
}


export default Customize;

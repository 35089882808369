import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Image,
  Spinner,
  Modal,
  Alert,
  Badge,
  Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  faAward,
  faVideo,
  faUsers,
  faChalkboardTeacher,
  faLightbulb,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AcademyNavbar from "../AcademyNavbar";
import AcademyFooter from "../AcademyFooter";
import firebase from "firebase";
import { library } from "@fortawesome/fontawesome-svg-core";
import RequestBrochure from "../RequestBrochure";
import TextareaAutosize from "react-textarea-autosize";
import { useHistory } from "react-router-dom";

library.add(faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb);

function AlertDismissible({ showAlert, handleClose, handleAlertHide }) {
  return (
    <div
      className="col-10"
      style={{ position: "absolute", zIndex: 10, top: "10%" }}
    >
      <Alert show={showAlert} variant="dark">
        <Alert.Heading>Tem certeza de que quer sair?</Alert.Heading>
        <hr />
        <div className="d-flex justify-content-end">
          <Button onClick={handleAlertHide} variant="light" className="mx-2">
            Não
          </Button>

          <Button onClick={handleClose} variant="dark" className="mx-2">
            Sim, sair
          </Button>
        </div>
      </Alert>
    </div>
  );
}

function RequestBrochureModal({ programId, programType, brochure, history }) {
  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const handleClose = () => {
    setShow(false);
    setShowAlert(false);
  };
  const handleShow = () => setShow(true);
  const handleAlertShow = () => setShowAlert(true);
  const handleAlertHide = () => setShowAlert(false);
  window.localStorage.setItem("requestedbrochureURL", JSON.stringify(brochure));

  useEffect(() => {
    window.scrollTo(0, 0);
    // window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("recaptcha-container", {
    //     size:"invisible"
    // });
  }, []);

  return (
    <>
      <Button
        className="mr-2"
        variant="outline-light"
        size="sm"
        onClick={handleShow}
      >
        <span className="font-weight-bold mb-0">Solicitar panfleto</span>
      </Button>

      <Modal
        onEscapeKeyDown={handleAlertShow}
        onHide={handleAlertShow}
        centered
        show={show}
        // onHide={handleClose}
      >
        {/* <Modal.Header closeButton>
                    <Modal.Title>Request Brochure</Modal.Title>
                </Modal.Header> */}
        <Modal.Body className="border-white">
          <div className="d-flex align-items-center justify-content-center">
            <AlertDismissible
              showAlert={showAlert}
              handleClose={handleClose}
              handleAlertHide={handleAlertHide}
            />
          </div>
          <RequestBrochure
            programId={programId}
            programType={programType}
            brochure={brochure}
            history={history}
          />
        </Modal.Body>
        {/* <Modal.Footer>
                        <Button variant="light" onClick={handleClose}>Close</Button>
                        <Button variant="dark" onClick={handleClose}>Done</Button>
                    </Modal.Footer> */}
      </Modal>
    </>
  );
}

function ShortCourse(props) {
  const [price, setPrice] = useState(95000);
  const [count, setCount] = useState(0);

  const [variantBio, setVariantBio] = useState({ value: 0 });

  const [programId, setProgramId] = useState("");
  const [programType, setProgramType] = useState("");
  const [programThumbnail, setProgramThumbnail] = useState("");

  const [aboutProgram, setAboutProgram] = useState("");
  const [certificationAuthority, setCertificationAuthority] = useState("");
  const [faculty, setFaculty] = useState([]);
  const [learningJourney, setLearningJourney] = useState({});
  const [learningObjectives, setLearningObjectives] = useState({});
  const [programFeatures, setProgramFeatures] = useState([]);
  const [programName, setProgramName] = useState("");
  const [programShortDescription, setProgramShortDescription] = useState("");
  const [tags, setTags] = useState([]);
  const [who, setWho] = useState({});
  const [completeProgramData, setCompleteProgramData] = useState({});
  const [contacts, setContacts] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const [admin, setAdmin] = useState(false);

  const [alreadyAdded, setAlreadyAdded] = useState(false);
  const [cartOrderIds, setCartOrderIds] = useState([]);
  const [orderCount, setOrderCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();
  const [checkOutPopOver, setCheckOutPopOver] = useState(false);
  const [userDetailsModalShow, setUserDetailsModalShow] = useState(false);
  const [email, setEmail] = useState("");
  const [couponID, setCouponID] = useState("");
  const [emailError, setEmailError] = useState({});
  const [couponError, setCouponError] = useState({});
  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="bg-dark text-white">
          <Modal.Title id="contained-modal-title-vcenter">
            Programa adicionado ao carrrinho!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-dark text-white">
          <p>{props.programName} foi adicionado ao carrinho com sucesso.</p>
          <p>Você quer finalizar a compra ou continuar?</p>
        </Modal.Body>
        <Modal.Footer className="bg-dark text-white">
          <Button variant="outline-light" size="sm" onClick={props.onHide}>
            Continuar navegando
          </Button>
          <Link to={`/enroll/`}>
            <Button variant="outline-light" size="sm">
              <span className="mr-2">Sair</span>
              <Badge variant="dark">{cartOrderIds.length}</Badge>
            </Button>
          </Link>
        </Modal.Footer>
      </Modal>
    );
  }

  function LiveClasses(sessions) {
    for (var i = 0; i < sessions; i++) {
      return (
        <>
          <span className="livemarker">LIVE</span>
        </>
      );
    }
  }

  useEffect(() => {
    console.log("Executing Initial useEffect Process.");
    console.log(props.match.params.id);

    let recentViews = window.localStorage.getItem("recentViews")
      ? JSON.parse(window.localStorage.getItem("recentViews"))
      : [];

    firebase
      .firestore()
      .doc(`/Variants/pt-BR/shortCoursesList/${props.match.params.id}`)
      .get()
      .then((programListDoc) => {
        if (programListDoc.exists) {
          const programListData = programListDoc.data();

          setProgramThumbnail(programListData?.images?.cropped);

          console.log("programListData", programListData);

          programListData.programData.get().then((programDoc) => {
            if (programDoc.exists) {
              setProgramId(programDoc.id);
              setProgramType("Short Course");
              const programData = programDoc.data();

              //fetching faculty

              programData.faculty.map((faculty) => {
                firebase
                  .firestore()
                  .doc("faculty/" + faculty.id)
                  .get()
                  .then((fac) => {
                    setFaculty((prev) => [...prev, fac.data()]);
                  });
              });

              setCompleteProgramData(programData);

              setAboutProgram(programData?.programDesciption);
              setLearningJourney(programData.learningPoints);
              setProgramName(programData.programName);
              setLoadingData(false);
              setIsLoading(false);
            } else {
              console.log("Program Data not found.");
              setIsLoading(false);
            }
          });
        } else {
          console.log("Program Data not found.");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("Unable to fetch Program data.", error);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    // let pastCartOrderIds = window.localStorage.getItem("enrollProgramIds")
    //   ? JSON.parse(window.localStorage.getItem("enrollProgramIds"))
    //   : [];
    // if (window.localStorage.getItem("enrollProgram")) {
    //   window.localStorage.removeItem("enrollProgram");
    // }
    // if (pastCartOrderIds.indexOf(programId) > -1) {
    //     setAlreadyAdded(true)
    // }
    // setCartOrderIds(pastCartOrderIds);
  }, [programId, orderCount]);

  // useEffect(() => {
  //     // check whether the url has ref or not
  //     console.log(props)
  //     if(!props.match.params.ref || !props.match.params[0]){
  //         //if no ref in url, skip
  //     }else if(props.match.params.ref){
  //         // url has ref
  //         if(window.localStorage.getItem('ref')){
  //             // there is already a ref in local storage
  //             // console.log('LS has ref already!');
  //             // incrementing count of new ref but not replacing LS
  //             const indexRef = window.localStorage.getItem('ref');
  //             let ref = [];
  //             if( indexRef[0] === "["){
  //                 ref = JSON.parse(window.localStorage.getItem('ref'));
  //             }else{
  //                 ref = [window.localStorage.getItem('ref')];
  //             }
  //             ref.push(props.match.params.ref);
  //             firebase.firestore().collection('referrals').doc(props.match.params.ref).update({
  //                 referralLinkVisited : firebase.firestore.FieldValue.increment(1)
  //             })
  //             window.localStorage.setItem('ref', JSON.stringify(ref));
  //         }else if (!window.localStorage.getItem('ref')){
  //             // no ref in local storage
  //             const ref = [];
  //             ref.push(props.match.params.ref);
  //             window.localStorage.setItem('ref', JSON.stringify(ref));
  //             firebase.firestore().collection('referrals').doc(props.match.params.ref).update({
  //                 referralLinkVisited : firebase.firestore.FieldValue.increment(1)
  //             })
  //         }

  //     }
  // },[])

  const addToCart = () => {
    let pastCart = [];
    let pastCartOrderIds = [];

    setOrderCount((prev) => prev + 1);

    const completeProgramDataWithoutFaculty = { ...completeProgramData };
    delete completeProgramDataWithoutFaculty["faculty"];

    pastCart.push({
      ...completeProgramDataWithoutFaculty,
      programId,
      programThumbnail,
      programType,
    });
    window.localStorage.setItem("enrollProgram", JSON.stringify(pastCart));

    pastCartOrderIds.push(programId);
    window.localStorage.setItem(
      "enrollProgramIds",
      JSON.stringify(pastCartOrderIds)
    );

    setUserDetailsModalShow(true);
  };

  const continueToEnroll = (e) => {
    e.preventDefault();
    if (!email.trim().length) {
      setEmailError({
        message: "Please enter an email",
      });
    }
    if (couponID.trim().length && couponID.trim() !== "VELI") {
      setCouponError({
        message: "Invalid discount code",
      });
    }
    if (email.trim().length && couponID.trim().length && couponID.trim()) {
      window.localStorage.setItem("email", JSON.stringify(email));
      window.localStorage.setItem("couponID", JSON.stringify(couponID));
      history.push("/enroll");
    } else if (email.trim().length && !couponID.trim().length) {
      window.localStorage.setItem("email", JSON.stringify(email));
      window.localStorage.removeItem("couponID");
      history.push("/enroll");
    }
  };

  const addToCartSingle = () => {
    let pastCart = [];
    let pastCartOrderIds = [];

    // console.log("Checking: ", pastCartOrderIds.indexOf(programId))
    // console.log("pastCart", pastCart, "completeProgramData", completeProgramData)
    // console.log("programId", programId, "programType", programType, "programThumbnail", programThumbnail)

    // if (pastCartOrderIds.indexOf(programId) > -1) {
    //     alert("Already added")
    //     return;
    // }

    const completeProgramDataWithoutFaculty = { ...completeProgramData };
    delete completeProgramDataWithoutFaculty["faculty"];
    pastCart.push({
      ...completeProgramDataWithoutFaculty,
      programId,
      programThumbnail,
      programType,
    });
    window.localStorage.setItem("enrollProgram", JSON.stringify(pastCart));

    pastCartOrderIds.push(programId);
    window.localStorage.setItem(
      "enrollProgramIds",
      JSON.stringify(pastCartOrderIds)
    );
  };

  if (loadingData) {
    return (
      <div
        className="container-fluid w-100 d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" role="status" variant="light">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return isLoading ? (
    <div
      className="container-fluid w-100 d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <Spinner animation="border" role="status" variant="light">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  ) : (
    <div className="academyProgram">
      <AcademyNavbar />
      <div className="container-fluid pricing-bar py-3">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-4">
              <p className="font-weight-bold mb-0">
                <span className="d-none d-md-block pt-2">{programName}</span>
                {admin ? (
                  <span className="d-block d-md-none">
                    From{" "}
                    <input type="text" value={price} className="form-control" />
                    / participant
                  </span>
                ) : (
                  <span className="d-block d-md-none">
                    From US $199 / participant{" "}
                  </span>
                )}
              </p>
            </div>
            <div className="col-8 text-right">
              <p className="font-weight-bold mb-0">
                {admin ? (
                  <span className="d-none d-md-inline">
                    From{" "}
                    <input
                      type="text"
                      value={price}
                      className="form-control d-none d-md-inline w-25"
                    />
                    / participant
                  </span>
                ) : (
                  <span className="mr-2 d-none d-md-inline">
                    From US $199 / participant{" "}
                  </span>
                )}
                {/* <Button variant="primary d-inline font-weight-bold mb-0" style={{ marginTop: '-5px' }}>See dates</Button> */}
                <span className="d-none d-md-inline">
                  <RequestBrochureModal
                    programId={programId}
                    programType={programType}
                    brochure={completeProgramData.brochure}
                    history={props.history}
                  />
                </span>
                {/* <Button
                  variant="outline-light"
                  size="sm"
                  className="mr-2"
                  onClick={addToCart}
                >
                  <p className="font-weight-bold mb-0">Inscreva-se agora</p>
                </Button> */}
                {/* <Link to={`/enroll/`}>
                                    {cartOrderIds.length > 0
                                        ? 
                                        <Button variant="outline-light" size="sm">
                                            <span className="mr-2">Sair</span>
                                            <Badge variant="dark">{cartOrderIds.length}</Badge>
                                        </Button>
                                        : 
                                        null
                                        }
                                </Link> */}
                {/* <Link to={`/enroll/`}> */}
                {cartOrderIds.length > 0 ? (
                  <Button variant="outline-light" size="sm">
                    <span className="mr-2">Checkout</span>
                    <Badge variant="dark">{cartOrderIds.length}</Badge>
                  </Button>
                ) : alreadyAdded ? (
                  <Button variant="outline-light" size="sm">
                    <span className="mr-2">Already added</span>
                  </Button>
                ) : (
                  <Button variant="outline-light" size="sm" onClick={addToCart}>
                    <p className="font-weight-bold mb-0">Enroll Now</p>
                  </Button>
                )}
                {/* </Link> */}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-black pt-0 pt-md-5">
        <div className="container">
          <div className="mb-3 mb-md-5 flextry" style={{ width: "100%" }}>
            <div className="flex-container">
              {/* <div id="MainBanner" className="d-none d-md-block">
                                <video width="100%" controlsList="nodownload" controls loop muted style={{ maxWidth: "300px" }}>
                                    <source src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fsample-video.mp4?alt=media&token=1eccfe7a-8468-4f89-874e-f6dfbcf69e38" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div> */}
              <div
                className="cover-image col-12 col-md-6"
                style={{
                  flexGrow: "2",
                  backgroundImage: `url(${
                    programThumbnail
                      ? programThumbnail
                      : "https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/program-thumbnails%2Fexecutive-programs%2Fartificial-intelligence-principles-and-techniques.jpg?alt=media&token=bb045d37-d0b9-4b06-84a1-041e1989ca60"
                  })`,
                }}
              ></div>
              {/* <div>
                                <div style={{ backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2FAlice%20Li.jpg?alt=media&token=0717ae51-18a1-4cd0-b37b-eb5cfb8daa75)" }}>
                                    <span>Faculty 2</span>
                                </div>
                                <div style={{
                                    backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2FLutz%20Finger_Prof.jpg?alt=media&token=eda6c7ec-b3b0-45f1-bdd0-2ea69fc494d8)"
                                }}>
                                    <span>Faculty 3</span>
                                </div>
                            </div> */}
              {/* <div className="d-none d-md-block" style={{
                                backgroundImage: `url(${completeProgramData.facultyImageUrl})`
                            }}
                            >
                                <p>Inaugural address by Nobel Laureate A. Michael Spence</p>
                            </div> */}
              <div
                className="d-none d-md-block"
                style={{
                  backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2FYG5Z7272_new.jpg?alt=media&token=43b62a2b-0132-480d-aee5-6a6e19ce8b14")`,
                }}
              >
                {/* <p>Record of attendance from the University of California, Los Angeles (UCLA) Extension</p> */}
              </div>
            </div>
          </div>

          <div className="row program-header pb-5">
            <div className="col-md-6 text-white mb-3">
              {admin ? (
                <h1>
                  <TextareaAutosize
                    className="form-control"
                    value={programName}
                  />
                </h1>
              ) : (
                <h1 className="font-weight-light h2">{programName}</h1>
              )}
              {admin ? (
                <TextareaAutosize
                  className="form-control mb-2"
                  value={programShortDescription}
                />
              ) : (
                <p className="font-weight-light lead">
                  {completeProgramData.timeAndDate}
                </p>
              )}
              {!loadingData &&
                tags.map((item) => (
                  <span
                    key={item}
                    className="badge text-uppercase badge-dark mr-2 mb-2 font-weight-normal p-2"
                  >
                    {item}
                  </span>
                ))}
              <span className="d-block d-md-none">
                <RequestBrochureModal
                  programId={programId}
                  programType={programType}
                  brochure={completeProgramData.brochure}
                  history={props.history}
                />
              </span>
              {/* <div>
                                <p>Aprenda e se relacione por meio de aulas online ao vivo com professores de renome mundial da:</p>
                                <div className="row">
                                    {completeProgramData.associatedUniversities.map(university => 
                                        <p className="col-md-6 mb-1 font-weight-bold">{university}</p>
                                    )}
                                </div>
                            </div> */}
            </div>
            <div className="col-md-6 text-white ">
              <div className="border-dark border-top border-bottom py-2">
                <div className="row">
                  {/* <div className="col-md-4 pt-2 ">
                    <img
                      className="logo mb-3"
                      src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fucla_reverse.png?alt=media&token=adbdeab7-41e8-4d9a-a1a7-9f2d8fb3f5ac"
                      width="100%"
                    />
                  </div> */}
                  <div className="col-md-12 col-12 certification-text">
                    IMPORTANTE: ao participar e concluir os cinco (5) cursos o
                    participante terá o direito a receber o certificado da UCLA
                    Extension
                  </div>
                  <div className="col-12 mt-3 d-block d-sm-none">
                    <img
                      className="rounded w-100"
                      src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2FYG5Z7272_new.jpg?alt=media&token=43b62a2b-0132-480d-aee5-6a6e19ce8b14"
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                {!loadingData &&
                  programFeatures.map((feature, idx) => (
                    <div className="col-6" key={idx}>
                      {admin ? (
                        <>
                          <FontAwesomeIcon icon={feature.icon} color="grey" />
                          <small>
                            <input
                              className="form-control"
                              value={feature.content}
                            />
                          </small>
                        </>
                      ) : (
                        <>
                          <FontAwesomeIcon icon={feature.icon} color="grey" />
                          <p className="small">{feature.content}</p>
                        </>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="container-fluid bg-light py-5 program-content-web">
          <div className="container">
            <div className="row">
              {completeProgramData && completeProgramData.learningPoints ? (
                <>
                  <div className="col-md-4">
                    <h2>Foco</h2>
                  </div>
                  <div className="col-md-8 ">
                    <p>{completeProgramData.learningPoints.intro}</p>
                    <p>
                      <ol>
                        {completeProgramData.learningPoints.points.map(
                          (point, idx) => (
                            <li key={idx}>
                              <p className="w-100">
                                <h3>{point}</h3>
                              </p>
                            </li>
                          )
                        )}
                      </ol>
                    </p>
                  </div>
                </>
              ) : completeProgramData &&
                completeProgramData.programDescription ? (
                <>
                  <div className="col-md-4">
                    <h2>Ementa</h2>
                  </div>
                  <div className="col-md-8 ">
                    <p>{completeProgramData.programDescription}</p>
                  </div>
                </>
              ) : null}
            </div>
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-8">
                <h4>{completeProgramData.lastSession}</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid py-5 program-content-web bg-white">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <h2>Corpo docente de renome mundial</h2>
              </div>
              <div className="col-md-8">
                <p>
                  Aprenda e se relacione por meio de aulas online ao vivo com
                  professores de renome mundial da:
                </p>
              </div>
            </div>

            {!loadingData &&
              faculty.map((professor, idx) => (
                <div className="row mt-3" key={idx}>
                  <div className="col-md-4 pr-2 pr-md-3">
                    <img
                      className="img-fluid float-right"
                      style={{ height: 150, width: 150, borderRadius: 30 }}
                      src={professor.profilePicUrl}
                    />
                  </div>

                  <div className="col-md-8 ">
                    <p>
                      {admin ? (
                        <input
                          type="text"
                          className="form-control"
                          value={`${professor.fullName.firstName} ${professor.fullName.middleName} ${professor.fullName.lastName}`}
                        />
                      ) : (
                        <h5>
                          {`${professor.fullName.selectTitle}`}{" "}
                          {`${professor.fullName.firstName}`}{" "}
                          {professor.fullName.middleName
                            ? `${professor.fullName.middleName}`
                            : null}{" "}
                          {`${professor.fullName.lastName}`}
                        </h5>
                      )}
                      {admin ? (
                        <TextareaAutosize
                          className="form-control"
                          value={professor.description}
                        />
                      ) : (
                        <>
                          {!variantBio
                            ? professor.description
                                .split("\\n")
                                .map((para, idx) => <p key={idx}>{para}</p>)
                            : professor.descriptionVariants
                            ? professor.descriptionVariants[variantBio.value]
                                .split("\\n")
                                .map((para, idx) => <p key={idx}>{para}</p>)
                            : professor.description
                                .split("\\n")
                                .map((para, idx) => <p key={idx}>{para}</p>)}
                        </>
                      )}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <MyVerticallyCenteredModal
        show={checkOutPopOver}
        onHide={() => setCheckOutPopOver(false)}
        programName={completeProgramData.programName}
      />
      <Modal
        show={userDetailsModalShow}
        onHide={() => setUserDetailsModalShow(false)}
      >
        <Modal.Header closeButton>
          <h5>Enroll now</h5>
        </Modal.Header>
        <Form onSubmit={continueToEnroll} className="d-flex p-3 flex-column">
          <Form.Label className="col-12">
            Email address *
            <Form.Control
              type="email"
              placeholder="Email address"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value.trim().toLowerCase());
              }}
            ></Form.Control>
          </Form.Label>
          {emailError?.message ? (
            <Form.Text className="text-danger col-12">
              {emailError?.message}
            </Form.Text>
          ) : null}
          <Form.Label className="col-12">
            Discount code
            <Form.Control
              type="text"
              placeholder="Discount code"
              value={couponID}
              onChange={(e) => {
                setCouponID(e.target.value.trim().toUpperCase());
              }}
            ></Form.Control>
          </Form.Label>
          {couponError?.message ? (
            <Form.Text className="text-danger col-12">
              {couponError?.message}
            </Form.Text>
          ) : null}
          <Form.Text className="text-muted col-12">
            The fields marked with * are mandatory.
          </Form.Text>
          <Button
            type="submit"
            variant="outline-dark"
            className="col-12 w-50 mx-auto mt-3"
          >
            Continue
          </Button>
        </Form>
      </Modal>
      <AcademyFooter />
    </div>
  );
}

export default ShortCourse;

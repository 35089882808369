import React, { useEffect, useState } from 'react';
import { Card, Popover, OverlayTrigger, Button, Nav, NavDropdown, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import {
    Link, useHistory
} from "react-router-dom";
import AcademyNavbar from './AcademyNavbar'
import AcademyFooter from './AcademyFooter'
import firebase from 'firebase';
import background from '../../images/polygon.png'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

const categoryoptions = (
    <Popover id="popover-basic">
        <Popover.Title as="h3">Cateogry</Popover.Title>
        <Popover.Content>
            And here's some <strong>amazing</strong> content. It's very engaging.
        right?
      </Popover.Content>
    </Popover>
);

const Category = () => (
    <OverlayTrigger trigger="click" placement="bottom" overlay={categoryoptions}>
        <Button className="mb-5 mr-3 rounded small" variant="outline-light" style={{ borderColor: "#555555" }}>Category</Button>
    </OverlayTrigger>
);

const Dates = () => (
    <OverlayTrigger trigger="click" placement="bottom" overlay={categoryoptions}>
        <Button className="mb-5 mr-3 rounded small" variant="outline-light" style={{ borderColor: "#555555" }}>Dates</Button>
    </OverlayTrigger>
);

function About() {

    const handleChange = function (event) {
        console.log(event.target.value)
    }

    const history = useHistory();

    function handleClick() {
        history.push("/program");
    }

    const [programList, setProgramList] = useState([]);
    const [loadingProgramList, setLoadingProgramList] = useState(true);

    useEffect(() => {

        let programListRef = firebase.firestore().collection('programList');
        let allPrograms = programListRef.orderBy("position", "asc").get()
            .then(snapshot => {
                let tempProgramList = [];

                snapshot.forEach(doc => {
                    tempProgramList.push({ programSlug: doc.id, ...doc.data() });
                });
                setProgramList(tempProgramList);
                setLoadingProgramList(false);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });

    }, [])

    const [customizableData, setCustomizableData] = useState();
    const [heroHeading, setHeroHeading] = useState('');
    const [heroDesc, setHeroDesc] = useState('');
    const [mainSectionHeading, setMainSectionHeading] = useState('');
    const [mainSectionDesc, setMainSectionDesc] = useState('');
    const [sections, setSections] = useState([]);

    useEffect(() => {
        firebase.firestore().collection('/Variants/pt-BR/customizablePages/').doc('about').get()
            .then((doc) => {
                if(doc.exists){
                    setCustomizableData(doc.data());
                    setHeroHeading(doc.data().heroHeading);
                    setHeroDesc(doc.data().heroDesc);
                    setMainSectionHeading(doc.data().mainSectionHeading);
                    setMainSectionDesc(doc.data().mainSectionDesc);
                    setSections(doc.data().sections);
                }
            })
    },[]);

    return (
        <>
            <AcademyNavbar />
            <div className="container-fluid pt-5" style={{ backgroundImage: `url(${background})` }}>
                <div className="container">
                    <div className="row py-4" style={{ borderBottom: "1px solid rgba(255,255,255,0.2)" }}>
                        <div className="col-md-4 text-white">
                            <h1 className="font-weight-light mb-3">{ReactHtmlParser(heroHeading)}</h1>
                            
                        </div>
                        <div className="col-md-6 text-light pt-3" style={{ overflow: "hidden" }}>
                        <p className="lead">{ReactHtmlParser(heroDesc)}</p>

                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid bg-light py-5" style={{
                backgroundSize: 'cover', backgroundPosition: 'bottom right',
                backgroundImage: 'url(http://themesmummy.com/mitech/static/130fe9ab91ac2fc94b9b15afd0b37809/8c24d/title-bar-01-bg.jpg)'
            }}>
                <div className="container">
                    <div className="row" style={{ borderBottom: "1px solid rgba(255,255,255,0.2)" }}>
                        <div className="col-md-4 text-dark ">
                            <h2 className="font-weight-light">{ReactHtmlParser(mainSectionHeading)}</h2>
                        </div>
                        <div className="col-md-6" style={{ overflow: "hidden" }}>
                            {ReactHtmlParser(mainSectionDesc)}
                        </div>
                    </div>


                </div>
            </div>
            <div className="container-fluid bg-light py-5" style={{}}>
                <div className="container">
                {
                                sections.map((section, index) => {
                                    return(
                                    <div className="row" style={{ borderBottom: "1px solid rgba(255,255,255,0.2)" }}>
                                        <div className="col-md-4 text-dark ">
                                            <h2 className="font-weight-light ckeditorNoMargin">
                                                {ReactHtmlParser(section.sectionHeading)}
                                            </h2>  
                                        </div>
                                        <div className="col-md-6" style={{ overflow: "hidden" }}>
                                            {ReactHtmlParser(section.sectionDesc)}
                                        </div>
                                     </div>
                                    )
                                })
                }
                </div>
            </div>
            <AcademyFooter />
        </>
    );
}

export default About;
import React, { useEffect, useState, useContext } from 'react';
import { LeadsAccessContext } from '../Academy/Auth';
import {
    Card,
    Button,
    Image,
    Spinner,
    Modal,
    Alert,
    Badge
} from 'react-bootstrap';
import {
    Link
} from "react-router-dom";
import { faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AcademyNavbar from '../Academy/AcademyNavbar';
import AcademyFooter from '../Academy/AcademyFooter';
import firebase from 'firebase';
import { library } from '@fortawesome/fontawesome-svg-core';
import DashboardNavbar from '../Dashboard/DashboardNavbar';
import { Formik, Field, Form, ErrorMessage, FieldArray, yupToFormErrors, getIn} from 'formik';
import DatePickerLeadsComments from './DatePickerLeadsComments';
import * as Yup from 'yup';
import {AuthContext} from '../Academy/Auth';
import moment from 'moment';
library.add(faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight);

const OnlyLeadsComments = (props) => {
    const [lead, setLead] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [referrals, setReferrals] = useState([]);
    const onlyLeadsAccess = useContext(LeadsAccessContext);
    const [userNames, setUserNames] = useState([]);
    const [currentLeadID, setCurrentLeadID] = useState('');
    const [stage, setStage] = useState('');
    const [remarks, setRemarks] = useState('');

    const {currentUser} = useContext(AuthContext);

    useEffect(() => {
        if(!onlyLeadsAccess){
            props.history.push('/dashboard/')
        }
    })

    //checking whether url has state or not 
    useEffect(() => {
        if(!props.location.state || !props.location.state.leadid ){
            props.history.push('/leads/');
        }
    },[])
    // Getting lead
    useEffect(() => {
        if(props.location.state && props.location.state.leadid ){
            firebase.firestore().collection('leads').doc(props.location.state.leadid).get()
            .then((doc) => {
                if(doc.exists){
                    setLead(doc.data());
                }
            })
        }
    }, [])
    // Getting userNames
    useEffect(() => {
        if(lead){
            firebase.firestore().collection('academyUsers').get()
            .then((snapshot) => {
                let tempList = [];
                snapshot.forEach(doc => {
                    tempList.push({id: doc.id, firstName: doc.data().firstName, lastName: doc.data().lastName})
                })
                setUserNames(tempList);
            })
        }
    }, [lead])
    // time conversion
    useEffect(() => {
        if(lead.comments){
            lead.comments.map((comment) => {
                comment.time = comment.time.toDate()
                return(comment)
            })
        }
    }, [lead])
    // Getting programs
    useEffect(() => {
        let programsRef = firebase.firestore().collection('executivePrograms');
        programsRef.get()
            .then(snapshot => {
                let tempProgramsList = [];
                snapshot.forEach(doc => {
                    tempProgramsList.push({ programId: doc.id, ...doc.data() });
                });
                setPrograms(tempProgramsList);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
    }, [])
    // Getting referrals
    useEffect(() => {
        let referralsRef = firebase.firestore().collection('referrals');
        referralsRef.get()
            .then(snapshot => {
                let tempReferrals = [];
                snapshot.forEach(doc => {
                    tempReferrals.push(doc.data());
                });
                setReferrals(tempReferrals);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
    }, [])

    const getProgramNameHandler = (id) => {
        let programName = '';
        programs.forEach((program) => {
            if(program.programId === id){
                programName = program.programName;
            }
        })
        return programName;
    }
    
    const getReferralNameHandler = (id) => {
        let referralName = '';
        referrals.forEach((referral) => {
            if(referral.ref === id){
                referralName = referral.firstName + " " + referral.lastName;
            }
        })
        return referralName;
    }

    const editLeadHandler = (leadid) => {
        props.history.push({pathname: '/editlead', state: {leadid}})
    }

    //validation
    const RemarksSchema = Yup.object().shape({
        comments: Yup.array()
            .of(
                Yup.object().shape({
                    remark: Yup.string().required('Required'),
                    time: Yup.string().required('Required')
                })
            ),
        nextAction: Yup.string().required('Required'),
        nextdoa: Yup.string().required('Select a date')
    });
    // error messages
    const ErrorMessages = ({ name }) => (
        <Field
        name={name}
        render={({ form }) => {
            const error = getIn(form.errors, name);
            const touch = getIn(form.touched, name);
            return touch && error ? <div className="text-danger ml-2">{error}</div> : null;
        }}
        />
    );

    const getAddedByNameHandler = (uid) => {
        const name = userNames.map((users) => {
            if(users.id === uid){
                return(users.firstName + ' ' + users.lastName)
            }
        })
        return name;
    }

      


    return (
        <>
            <DashboardNavbar />
            <div className="container">
                <div className="d-flex align-items-center justify-content-between flex-column flex-md-row">
                    <h3 className="text-white mt-3 mb-3">Leads</h3>
                </div>
                <table class="table table-responsive-md table-hover table-dark m-0">
                <thead>
                    <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Program Name</th>
                    <th scope="col">Referred By</th>
                    <th scope="col">Date</th>
                    <th scope="col" className="text-center">Stage</th>
                    </tr>
                </thead>
                <tbody>

                                <tr>
                                <td style={{width: "15%"}}><span className="text-capitalize">{lead.firstName} {lead.lastName}</span>
                                    <br />
                                    <small>
                                        {lead.email}<br />
                                        {lead.phoneNumber}<br />
                                    </small>
                                </td>
                                <td style={{width: "25%"}}>{getProgramNameHandler(lead.programId)}</td>
                                <td>{lead.ref ? getReferralNameHandler(lead.ref) : 
                                <Button variant="link" className="btn-link"   onClick={() => {editLeadHandler(lead.leadsId)}}>Update</Button>
                                }</td>
                                <td>{lead.timestamp ? lead.timestamp.toDate().toDateString() : null}</td>
                                <td className="text-center">
                                    {lead.stage ? lead.stage : 'Uncontacted'}
                                </td>

                                </tr>
                                </tbody>
            </table>
            <div className="bg-dark p-3 text-white">
                    <>
                    {
                        lead.comments &&
                   
                    <div className="row">
                        <div className="col col-sm-3">
                            <span className="text-white">Date</span>
                        </div>
                        <div className="col col-sm-6">
                            <span>Remark</span>
                        </div>
                        <div className="col col-sm-3">
                            <span className="text-white">Added By</span>
                        </div>
                    </div>
                    }
                    {
                        lead.comments && lead.comments.map((comment) => (
                            <div className="row my-3">
                                <div className="col col-sm-3">
                                    <span>{moment(comment.time).format('LL')}</span>
                                </div>
                                <div className="col col-sm-6">
                                    <span>{comment.remark}</span>
                                </div>
                                <div className="col col-sm-3">
                                    <span>{comment.addedBy ? getAddedByNameHandler(comment.addedBy) : ''}</span>
                                </div>
                            </div>
                        ))
                    }
                    </>
            </div>
            <Formik
            initialValues = {{
                comments: [{
                    remark: '',
                    time: new Date(),
                    addedBy: currentUser.uid,
                }],
                nextAction: '',
                nextdoa: new Date()
            }}
            validationSchema={RemarksSchema}
            enableReinitialize
            onSubmit={(values, { resetForm }) => {
                let oldComments = [];
                if(lead.comments && lead.comments.length > 0){
                    // if comments already exist in the lead data
                    oldComments = lead.comments;
                }
                oldComments = [...oldComments, ...values.comments];
                firebase.firestore().collection('leads').doc(props.location.state.leadid)
                    .update({
                        comments: oldComments,
                        nextAction: values.nextAction,
                        nextdoa: values.nextdoa
                    }).then(() => {
                    alert('Remarks Updated!');
                    resetForm();
                    props.history.push('/leads/')
                })
            }}
            >
                {({ errors, touched, isValidating, values }) => (
                <Form className="bg-dark p-3">
                            <span className="text-white">Add Remark</span>
                            <div className="row">
                            <span className="text-white col-md-2">Remark Date</span>
                            <span className="text-white col-md-5">Remark</span>
                            <span className="text-white col-md-2">Next action</span>
                            <span className="text-white col-md-2">Date of next action</span>
                            <FieldArray name="comments">
                            {({ insert, remove, push }) => (
                                <>
                                {values.comments.length > 0 &&
                                values.comments.map((comment, index) => (
                                    <div className="form-group col-sm-12" key={index} style={{display: 'flex', alignItems: 'center'}}>
                                        <div className="col-md-2 text-white p-0 pr-3">
                                        <DatePickerLeadsComments name={`comments[${index}].time`} />
                                        </div>
                                        <ErrorMessages name={`comments[${index}].time`} />
                                        <div className="col-md-5 p-0">
                                            <Field
                                            name={`comments[${index}].remark`}
                                            id={`comments[${index}].remark`}
                                            placeholder="Remark"
                                            type="text"
                                            className='form-control'
                                            />
                                        </div>
                                        <ErrorMessages name={`comments[${index}].remark`} />

                                        <div className="col-md-2">
                                            <Field
                                            name={`nextAction`}
                                            id={`nextAction`}
                                            placeholder="Next Action"
                                            as="select"
                                            className='custom-select'
                                            >
                                            <option disabled  value="" selected>Next action</option>
                                            <option value="To Contact">To Contact</option>
                                            <option value="To Follow Up">To Follow Up</option>
                                            </Field>
                                        </div>
                                        {errors.nextAction && touched.nextAction ? (
                                            <div className="text-danger ml-2">{errors.nextAction}</div>
                                            ) : null}
                                        <div className="col-md-2 text-white">
                                            <DatePickerLeadsComments minDate={new Date()} name={`nextdoa`} id="nextdoa" />
                                        </div>

                                        <div className="col">
                                                <button
                                                type="button"
                                                className="secondary btn btn-danger btn-cross"
                                                onClick={() => remove(index)}
                                                >
                                                X
                                                </button>
                                        </div>

                                    </div>
                                ))}
                            <div className="mx-auto text-center">
                                <button
                                type="button"
                                className="secondary mt-3 mb-3 btn btn-outline-light btn-sm"
                                onClick={() => push()}
                                >
                                Add Remark
                                </button>
                            </div>
                            </>
                            )}
                            </FieldArray>

                            </div>

                    <div style={{textAlign: 'center'}}> 
                        <button type="submit" className="btn btn-primary mt-3 btn-sm">Update Remarks</button>
                    </div>
                </Form>
                )}
            </Formik>
            </div>
            <AcademyFooter />
        </>
    );
}


export default OnlyLeadsComments;
